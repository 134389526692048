<template>
  <div class="cmp-product-price" v-if="showPrices" :class="parentClassRef">
    <ul class="price-list flex-row-nowrap align-items-baseline">

      <template v-if="!prices.showBasePrice">
        <li class="default">
          <span>{{ prices.price }}</span>
          <!-- BESPOKE GS: Added excl vat label -->
          <span v-if="showExclLabel" v-translation="{ type: 'label', code: 'label_bespoke_excl_vat'}" class="excl-vat-label"></span>
          <!-- END BESPOKE GS -->
        </li>
        <li v-if="prices.isSalesAction" class="original salesaction">
          <span>{{ prices.basePrice }}</span>
        </li>
      </template>
      <template v-else>
        <li class="base-price">
          <span>{{ prices.basePrice }}</span>
        </li>
        <li class="sales-price">
          <span class="cust-price">{{ prices.price }}</span>
          <span v-if="showExclLabel" v-translation="{ type: 'label', code: 'label_bespoke_excl_vat'}" class="excl-vat-label"></span>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    prices: { type: Object, required: false },
    parentClassRef: { type: String, default: '', required: false },    
    // BESPOKE GS
    showExclLabel: { type: Boolean, required: false, default: true }
  // END BESPOKE GS
  },
  computed: {
    ...mapGetters(['showPrices'])
  }
}
</script>

<style>

</style>
