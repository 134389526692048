<template>
  <div class="cmp-product-carousel utlz-reset utlz-row blocks" :class="{ 'carousel-enabled': useCarousel }">
    <div class="utlz-col-12">
      <h2>{{ title }}</h2>
    </div>
    <!-- BESPOKE GS: Changed blockSize to 3 -->
    <div class="utlz-col-12">
      <transition-group name="fade" tag="div" class="scroll-container list-items-wrapper utlz-row">
        <utlz-product-list-item
          @navigateToProductPage="navigateToProductPage($event)"
          v-for="product in products"
          :key="product.id"
          :product="product"
          :blockSize="3"
          class="product-list-item">
        </utlz-product-list-item>
      </transition-group>
    </div>
    <!-- END BESPOKE GS -->
    <template v-if="useCarousel">
      <div class="nav-controls">
        <transition name="fade">
          <div @click="scroll('left')" v-if="showControlLeft" class="nav-control left text-link"><i aria-hidden="true" class="fa fa-chevron-left"></i></div>
        </transition>
        <transition name="fade">
          <div @click="scroll('right')" v-if="showControlRight" class="nav-control right text-link">
            <i aria-hidden="true" class="fa fa-chevron-right"></i></div>
        </transition>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ProductListItem from 'src/components/webshop/products/product-list-item/ProductListItem.vue';
export default {
  components: {
    'utlz-product-list-item': ProductListItem
  },
  props: {
    title: { type: String, required: false, default: '' },
    blockSize: { type: Number, required: false, default: 4 },
    products: { type: Array, required: true }
  },
  data () {
    return {
      // BESPOKE GS
      // Always show left control
      showControlLeft: true,
      // END BESPOKE GS
      showControlRight: true
    }
  },
  computed: {
    ...mapGetters(['screenWidth']),
    useCarousel () {
      if (this.screenWidth < 576) {
        return this.products.length > 1;
      } else if (this.screenWidth < 992) {
        return this.products.length > 3;
      } else {
        return this.products.length > (12 / this.blockSize);
      }
    }

  },
  methods: {
    scroll (direction) {
      const scrollContainer = document.querySelector('.scroll-container');
      if (direction === 'left') {
        scrollContainer.scrollBy({
          left: -15,
          behavior: 'smooth'
        })
      } else if (direction === 'right') {
        scrollContainer.scrollBy({
          left: scrollContainer.scrollLeft + 15,
          behavior: 'smooth'
        })
      }
      
      // setTimeout(() => {        
      //   this.showControlLeft = scrollContainer.scrollLeft > 0;
      //   // BESPOKE 
      //   var totalScroll = scrollContainer.offsetWidth + scrollContainer.scrollLeft;
      //   // Add a 25 correction to compensate for fractioned pixels of the container childrens' width
      //   this.showControlRight = totalScroll + 25 <= scrollContainer.scrollWidth;
      //   // END BESPOKE
      // }, 350)
    }
  }
}
</script>

<style>

</style>
