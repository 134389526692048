<template>
  <div class="cmp-shopping-cart-overview utlz-reset utlz-row" :class="tableClasses">

    <!-- BESPOKE GS-684  -->
    <utlz-scan-tool v-if="bespokeUserInformation.isRepresentative"></utlz-scan-tool>
    <!-- END BESPOKE GS-684 -->

    <div class="checking-stock-loader" v-if="showStockLoader">     
      <span class="close-button"><i aria-hidden="true" class="fa fa-times-circle" @click="setShowStockLoader(false)"></i></span>
      <div class="loader"></div>
      <h2 v-translation="{ code: 'title_checking_stock', type: 'title' }"></h2>
      <p v-translation="{ type: 'label', code: 'label_checking_stock' }"></p>
    </div>
    <transition name="fadein">
      <utlz-prompt
        v-if="showDeleteProductPrompt"
        :payload="promptPayload.lineId"
        @confirm="deleteOrderLineFromCart($event)"
        @cancel="cancelPrompt()"
      >
      <template v-slot:prompt-content>
        <div class="delete-product-prompt utlz-row">
          <div class="utlz-col-12">
            <h2>
              <span v-translation="{ type: 'title', code: 'title_prompt_delete_from_cart' }"></span>
            </h2>
          </div>
          <div class="utlz-col-3 img">
            <img :src="promptPayload.product.imageUrl">
          </div>
          <div class="utlz-col-9">
            <h3>
              {{ promptPayload.product.shortDescription }}
            </h3>
          </div>
        </div>
      </template>

      </utlz-prompt>
    </transition>

    <transition name="fadein">
      <utlz-prompt
        v-if="showEmptyCartPrompt"
        @confirm="emptyCart()"
        @cancel="cancelEmptyCartPrompt()"
      >

      <template v-slot:prompt-content>
        <h2 v-translation="{ type: 'text', code: 'txt_confirm_clear_shopping' }"></h2>
      </template>

      </utlz-prompt>
    </transition>

    <transition name="fadein">
      <utlz-static-modal v-if="validatingStock">
        <template v-slot:static-modal-content>
          <div class="validate-stock-modal utlz-row">
            <div class="utlz-col-12 flex-row-nowrap align-center just-center">
              <span class="loader"></span>
              <h2>
                <span v-translation="{ type: 'label', code: 'label_validating_stock' }"></span>
              </h2>
            </div>
          </div>
        </template>
      </utlz-static-modal>
    </transition>

    <transition-group name="fade" tag="div" class="ph-wrapper utlz-col-12">
      <template v-if="loadingOrderLines">
        <div v-for="index in 4" :key="index" class="ph-silhouette orderlist-row">
          <span class="silhouette-img"></span>
          <div class="ph-paragraph">
            <span class="silhouette-line"></span>
            <span class="silhouette-line"></span>
          </div>
        </div>
      </template>
    </transition-group>

    <div v-if="shoppingCartEmpty" class="empty-cart-message col-md-12">
      <h2 v-translation="{ type: 'title', code: 'title_shopping_cart_empty' }"></h2>
    </div>

    <!-- BESPOKE GS-580: Add button to scroll to bottom of shoppingcart lines-->
    <div id="scroll-anchor-top" class="utlz-col-md-12 scroll-anchor" v-if="showScrollButtons && !loadingOrderLines">
      <a @click="scrollToBottom()" class="scroll-btn top">
        <i class="fa fa-angle-down" aria-hidden="true"></i>
      </a>
    </div>
    <!-- END BESPOKE GS-580 -->

    <transition name="fade">
      <div v-if="showOrderLines && screenWidth >= 880" class="orderline-header utlz-col-md-12">
        <div class="orderlines-wrapper">
          <div v-if="!hideShoppingCartImages" class="prod-img orderline-cell"></div>
          <div class="prod-desc orderline-cell">
            <span v-translation="{ type: 'label', code: 'label_header_prod_desc' }"></span>
          </div>

          <div v-if="bespokeUserInformation.isRepresentative" class="prod-condition orderline-cell">
            <span v-translation="{ type: 'label', code: 'label_header_prod_condition' }"></span>
          </div>

          <div v-if="bespokeUserInformation.isRepresentative" class="stock-sent orderline-cell">
            <span v-translation="{ type: 'label', code: 'label_header_stock_sent' }"></span>
          </div>

          <!-- BESPOKE GS: Hide price header when using counter with reversed values true = false because of the checkbox postback sequence  -->
          <div v-if="showPrices" class="prod-price orderline-cell">
            
            <span v-translation="{ type: 'label', code: 'label_header_prod_price' }"></span>
          </div>
          <!-- END BESPOKE GS -->

          <!-- BESPOKE GS: Disable prod stock -->
          <!-- <div class="prod-stock orderline-cell">
            <span v-translation="{ type: 'label', code: 'label_header_prod_stock' }"></span>
          </div> -->
          <!-- END BESPOKE GS -->

          <div class="order-product orderline-cell">
            <span v-translation="{ type: 'label', code: 'label_header_prod_quantity' }"></span>
          </div>

          <!-- BESPOKE GS: Hide price header when using counter with reversed values true = false because of the checkbox postback sequence  -->
          <div v-if="showPrices" class="prod-total orderline-cell">
            <span v-translation="{ type: 'label', code: 'label_header_prod_total' }"></span>
          </div>
          <div v-if="!showPrices" class="prod-total orderline-cell">
          </div>
           <!-- END BESPOKE GS -->

          <!-- BESPOKE GS: Added column header for order-comment and stock-modals, which may or may not be shown depending on if a user is representative -->
          <div v-if="bespokeUserInformation.isRepresentative" class="stock-modals orderline-cell"></div>
          <div v-if="!bespokeUserInformation.isRepresentative" class="order-comment orderline-cell"></div>
          <!-- END BESPOKE GS -->

          <div class="delete-prod orderline-cell">
          </div>
        </div>
      </div>
    </transition>

    <transition-group name="fade" tag="div" class="orderline-table utlz-col-md-12">
      <template v-if="showOrderLines">
        <div v-for="orderLine in orderLines" :key="orderLine.lineId" class="orderline-row">
          <div class="upper-row">
            <div v-if="!hideShoppingCartImages" class="prod-img orderline-cell">
              <a :href="orderLine.product.targetUrl">
                <img :src="orderLine.product.imageUrl" />
              </a>
            </div>

            <div class="prod-desc orderline-cell">
              <a :href="orderLine.product.targetUrl">
                <h3 class="prod-title body-text">{{ orderLine.product.shortDescription }}</h3>              
              </a>            
                <!-- BESPOKE GS: Wrapped prod code together with bespoke fields -->
                <div class="product-status">
                  <span class="status-label">A:</span>
                  <span class="prod-code">{{ orderLine.product.id }}</span>
                  <template v-if="orderLine.customFields.Location !== null && orderLine.customFields.Location !== ''">
                    <div class="status-wrapper" v-if="bespokeUserInformation.isRepresentative">
                      <span class="status-label">L:</span>
                      <span class="location">{{ orderLine.customFields.Location }}</span>
                    </div>                  
                  </template>
                  <template v-if="orderLine.customFields.direct_delivery !== undefined ? orderLine.customFields.direct_delivery.toString().toLowerCase() === 'true': false ">
                    <div class="status-wrapper">
                      <span class="direct-delivery" v-translation="{ type: 'label', code: 'label_direct_delivery' }"></span>
                    </div>
                  </template>

                    <!-- Stock status -->
                    <template v-if="orderLine.customFields.StockStatus !== 'undefined'">
                      <div class="status-wrapper">
                        <span v-if="orderLine.customFields.StockStatus == 1" class="stock-status-code" v-translation="{ type: 'message', code: 'message_shopping_cart_backorder' }"></span>
                        <span v-else-if="orderLine.customFields.StockStatus == 2" class="stock-status-code" v-translation="{ type: 'message', code: 'message_shopping_cart_no_direct_del' }"></span>
                        <span v-else-if="orderLine.customFields.StockStatus == 3" class="stock-status-code" v-translation="{ type: 'message', code: 'message_shopping_cart_unavailable' }"></span>
                        <span v-else-if="orderLine.customFields.StockStatus == 4" class="stock-status-code" v-translation="{ type: 'message', code: 'message_adjusted_for_location' }"></span>
                        <span v-else-if="orderLine.customFields.StockStatus == 6" class="stock-status-code" v-translation="{ type: 'message', code: 'message_shopping_cart_no_increase' }"></span>
                      </div>
                    </template>
                    
                  <!-- END BESPOKE GS -->
                </div>
            </div>

            <div v-if="bespokeUserInformation.isRepresentative" class="prod-condition orderline-cell">
              {{ orderLine.product.customStrings.CONDITION }}
            </div>

            <div v-if="bespokeUserInformation.isRepresentative" class="stock-sent orderline-cell">
              <span class="stock-sent-indicator positive" v-if="orderLine.product.customBooleans.STOCK_CONTROL"></span>
              <span v-else class="stock-sent-indicator negative"></span>
            </div>

            <div class="prod-price orderline-cell" v-if="orderLine.product.prices.length">
              <!-- BESPOKE GS: Added :showExclLabel="false" -->
              <utlz-product-price
                :prices="orderLine.product.prices[0]"
                :showExclLabel="false"
              ></utlz-product-price>
              <!-- END BESPOKE GS -->
            </div>
            <!--BESPOKE GS-712-->
            <div class="stock-status-wrapper text-placeholder pulse" v-if="!orderLine.product.prices.length && !bespokeGlobalWs.useCounter">
              <!--END BESPOKE GS-712-->
              <span class="loader"></span>
            </div>

            <!-- BESPOKE GS: Disable prod stock -->
            <!-- <div class="prod-stock orderline-cell">
              <utlz-product-stock
                :stock="orderLine.product.stock"
                :stockTotal="orderLine.product.stock"
              ></utlz-product-stock>
            </div> -->
            <!-- END BESPOKE GS -->

            <!-- BESPOKE GS-584: Added :class binding on checkingGsStock -->
            <div class="order-product orderline-cell" :class="{ 'checking-gs-stock': checkingGsStock }">
              <!-- END BESPOKE GS-584 -->

              <span v-if="loading" class="loader"></span>
              <!-- BESPOKE GS: Added :showFavorites="false" -->
              <utlz-order-product
                v-if="orderLine.product.stock !== null"
                :orderLine="orderLine"
                :product="orderLine.product"
                :initialQuantity="orderLine.quantity"
                :initialUnitCode="orderLine.unitCode"
                :useOrderMode="false"
                :totalOrderLinesQuantity="orderLine.totalOrderLinesQuantity"
                @quantityChanged="quantityChanged($event, orderLine)"
                @unitsChanged="unitsChanged($event, orderLine)"
                :showFavorites="false"
              ></utlz-order-product>
              <!-- END BESPOKE GS -->
            </div>

            <!-- BESPOKE GS: Added orderLine.totals.totalPrice so volume discount is taken into account, from a beta release  -->
            <div class="prod-total orderline-cell">
              <span>{{ orderLine.totals.totalPrice }}</span>
            </div>
            <!-- END BESPOKE GS -->

            <!-- BESPOKE GS: Added stock status buttons, only to be shown to representatives -->
            <div v-if="bespokeUserInformation.isRepresentative" class="stock-modals orderline-cell">
              <div class="stock-status">
                <a @click="openStockModal({ type: 'warehouseStock', id: orderLine.product.id })" class="stock-btn warehouse-stock">
                  <i class="fa fa-cube" aria-hidden="true"></i>
                </a>
                <a @click="openStockModal({ type: 'warehouseLocationStock', id: orderLine.product.id })" class="stock-btn warehouse-stock-location">
                  <i class="fa fa-hospital-o" aria-hidden="true"></i>
                </a>
                <a @click="openStockModal({ type: 'productEtaStock', id: orderLine.product.id })" class="stock-btn stock-eta">
                  <i class="fa fa-clock-o" aria-hidden="true"></i>
                </a>
              </div>
            </div>
            <!-- END BESPOKE GS -->          

            <!-- BESPOKE GS: Only show order comment in the main row if user is not a representative, since this changes the layout -->
            <template v-if="!bespokeUserInformation.isRepresentative">
              <div class="order-comment orderline-cell" :class="{ 'checking-gs-stock': checkingGsStock }">
                <template v-if="useOrderLineComments && screenWidth >= 880">
                  <utlz-order-line-comment
                    :orderLine="orderLine"
                    ></utlz-order-line-comment>
                </template>
              </div>
            </template>
            <!-- END BESPOKE GS -->

            <div class="delete-prod orderline-cell" :class="{ 'checking-gs-stock': checkingGsStock }">
              <span v-if="orderLine.customFields.original_line_number == '' || orderLine.customFields.original_line_number == undefined" class="close-button small grey" @click="promptDelete(orderLine)">
                <i class="fa fa-times-circle" aria-hidden="true"></i>
              </span>
            </div>          
          </div>
          <!-- BESPOKE GS: Added edit information and wrwapped it with comments in div.lower-row-->
          <div v-if="bespokeUserInformation.isRepresentative" class="lower-row">
            <div class="stock-status" :class="{ 'checking-gs-stock': checkingGsStock }">
              <!-- Only show edit delivery button if user is representative and order line is not in backorder -->
              <template v-if="bespokeUserInformation.isRepresentative && orderLine.customFields.StockStatus != 1">
                <div :class="{ 'checking-gs-stock': checkingGsStock }" class="delivery-options">                  
                  <div class="checkbox-control custom-checkbox">              
                    <input @change="saveDirectDelivery({ orderLine: orderLine, event: $event })" :id="'dd-checkbox-' + orderLine.lineId" type="checkbox" :checked="orderLine.customFields.direct_delivery !== undefined ? orderLine.customFields.direct_delivery.toString().toLowerCase() === 'true': false " />
                    <label :for="'dd-checkbox-' + orderLine.lineId" v-translation="{ type: 'label', code: 'label_modal_dd_checkbox' }"></label>
                  </div>
                  <div v-if="orderLine.customFields.increasable !== undefined ? orderLine.customFields.increasable.toString().toLowerCase() === 'true': true" class="input-control">   
                    <utlz-location-search-bar @saveLocation="saveLocation($event)" :orderLine="orderLine"></utlz-location-search-bar>
                  </div>
                </div>
              </template>
            </div>
            <!-- END BESPOKE GS -->
            <!-- BESPOKE GS: Added extra orderline comment that we show in a different position if the user is a representative div.order-comment with checking-gs-stock-->
            <div class="order-comment orderline-cell" :class="{ 'checking-gs-stock': checkingGsStock }">
              <template v-if="useOrderLineComments">
                <utlz-order-line-comment
                  :orderLine="orderLine"
                  ></utlz-order-line-comment>
              </template>
            </div>
          </div>
          <!-- END BESPOKE GS -->

          <!-- BESPOKE GS: Only show mobile order coment as a non representative, since as a representative this control is loaded in a different position -->
          <template v-if="useOrderLineComments && screenWidth < 880 && !bespokeUserInformation.isRepresentative">
            <div class="mobile-order-comment">
              <utlz-order-line-comment
                :orderLine="orderLine"
              ></utlz-order-line-comment>
            </div>
          </template>
          <!-- END BESPOKE GS -->

        </div>
      </template>

    </transition-group>

    <!-- BESPOKE GS-580: Add button to scroll to bottom of shoppingcart lines-->
    <div id="scroll-anchor-bottom" class="scroll-anchor utlz-col-md-12" v-if="showScrollButtons && !loadingOrderLines">
      <a @click="scrollToTop()" class="scroll-btn bottom">
        <i class="fa fa-angle-up" aria-hidden="true"></i>
      </a>
    </div>
    <!-- END BESPOKE GS-580 -->

    <template v-if="showEmptyShoppingCart && !shoppingCartEmpty && !loadingOrderLines">
      <div class="utlz-col-md-12 clear-cart-col">
        <a @click="showEmptyCartPrompt = true" v-translation="{ type: 'button', code: 'btn_clear_shopping_cart' }" class="button small outline clear-cart"></a>
      </div>
    </template>

    <div id="stock-warning" class="utlz-col-md-6">
      <transition name="fade">
        <template v-if="invalidOrderLines.length">
          <div class="stock-warning">
            <div class="stock-warning-wrapper">
              <h2 v-translation="{ type: 'title', code: 'title_shopping_cart_stock_warning' }"></h2>
              <ul>
                <li v-for="orderLine in invalidOrderLines" :key="orderLine.lineId">
                  <i class="fa fa-caret-right" aria-hidden="true"></i><span>{{ orderLine.product.shortDescription }}</span>
                </li>
              </ul>
            </div>
          </div>
        </template>
      </transition>

      <utlz-coupons v-if="useCoupons && showOrderLines"></utlz-coupons>

    </div>    

    <!-- BESPOKE -->
    <div v-if="bespokeUserInformation.isRepresentative && !shoppingCartEmpty" class="direct-delivery custom-checkbox utlz-col-12" :class="{ 'checking-gs-stock': checkingGsStock }">
      <input id="toggle-direct-delivery" type="checkbox" @change="setOrderLinesDirectDelivery($event)" />
      <label for="toggle-direct-delivery" v-translation="{ type: 'label', code: 'label_direct_delivery_chk' }"></label>
    </div>
    <!-- END BESPOKE GS -->

    <!-- BESPOKE GS: Added class column-totals -->
    <div class="utlz-col-md-6 column-totals">
      <transition name="fade">
        <utlz-shopping-cart-totals
          v-if="showOrderLines"
          :format="shoppingCartTotalsFormat"
        ></utlz-shopping-cart-totals>
      </transition>

      <!-- BESPOKE GS: Added bespoke shoppingcart totals, these are placed on the position of the uc_shopping_cart_totals at the bottom of the page -->
      <div id="bespoke-totals" class="utlz-reset">
        <utlz-shopping-cart-totals
          v-if="showOrderLines"
          :format="shoppingCartTotalsBespoke"
        ></utlz-shopping-cart-totals>
      </div>
      <!-- END BESPOKE GS -->

      <!-- BESPOKE GS: Added button to do a stock check -->
      <a 
        class="button theme-primary check-stock loader-btn btn-u-sea-shop" 
        v-translation="{ type: 'button', code: 'button_check_stock' }" 
        @click="doStockCheckOrderlines()"
        :class="{ 'active checking-gs-stock': checkingGsStock }"
      >
      </a>
      <!-- END BESPOKE GS -->
    </div>
    <!-- END BESPOKE GS -->

  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import Prompt from 'src/components/ui/prompt/Prompt.vue';
  import StaticModal from 'src/components/ui/static-modal/StaticModal.vue';
  import ProductPrice from 'src/components/webshop/product-price/ProductPrice.vue';
  import ProductStock from 'src/components/webshop/product-stock/ProductStock.vue';
  import OrderProduct from 'src/components/webshop/order-product/OrderProduct.vue';
  import Coupons from 'src/components/webshop/coupons/Coupons.vue';
  import OrderLineComment from 'src/components/webshop/order-product/blocks/OrderLineComment.vue';
  import ShoppingCartTotals from 'src/components/webshop/shopping-cart/ShoppingCartTotals.vue';
  import _ from 'lodash';

// BESPOKE GS
import ScanTool from './../scan-tool/ScanTool.vue';
import LocationSearchBar from './../location-search-bar/LocationSearchBar.vue';
// END BESPOKE GS

export default {
  components: {
    'utlz-prompt': Prompt,
    'utlz-static-modal': StaticModal,
    'utlz-product-price': ProductPrice,
    'utlz-product-stock': ProductStock,
    'utlz-order-product': OrderProduct,
    'utlz-coupons': Coupons,
    'utlz-order-line-comment': OrderLineComment,
    'utlz-shopping-cart-totals': ShoppingCartTotals,
    // BESPOKE GS
    'utlz-scan-tool': ScanTool,
    'utlz-location-search-bar': LocationSearchBar
  },
  data () {
    return {
      loading: false,
      showDeleteProductPrompt: false,
      promptPayload: null,
      showEmptyCartPrompt: false,
      // BESPOKE GS
      showScrollButtons: false,
      shoppingCartTotalsBespoke: ['OrderLinesTotalNet', 'OrderLinesTotalTax', 'OrderLinesTotal']
      // END BESPOKE
    }
  },
  computed: {
    // BESPOKE GS: Added bespokeUserInformation AND bespokeGlobalWs
    ...mapGetters(['screenWidth', 'showStock', 'useCoupons', 'useOrderLineComments', 'useMinimumOrderQuantity', 'hideShoppingCartImages', 'showEmptyShoppingCart', 'bespokeUserInformation', 'bespokeGlobalWs', 'showPrices']),
  ...mapGetters('shoppingCart', ['orderLines', 'invalidOrderLines', 'loadingOrderLines', 'orderLinesTotal', 'minimumOrderQuantity', 'shoppingCartEmpty', 'shoppingCartTotalsFormat', 'validatingStock', 'checkingGsStock', 'showStockLoader']),
   showOrderLines () {
      return this.orderLines.length > 0 && this.orderLines[0].product !== null;
    },
    tableClasses () {      let classes = '';
      if (!this.showStock) {
        classes += 'hide-stock ';
      }
      if (this.hideShoppingCartImages) {
        classes += 'hide-images ';
      }
      if (this.bespokeUserInformation.isRepresentative) {
        classes += 'cart-is-representative ';
      } else {
        classes += 'cart-non-representative ';
      }
      return classes;
    }
  },
  methods: {
    // BESPOKE GS
    // Added doStockCheckOrderlines, setOrderLinesDirectDelivery, updateQuantityWithStockCheck, setShowStockLoader
    ...mapActions('shoppingCart', [
      'initShoppingCart', 
      'updateOrderLineQuantity', 
      'deleteOrderLine', 
      'emptyShoppingCart',
      'doStockCheck', 
      'doStockCheckOrderlines', 
      'setOrderLinesDirectDelivery', 
      'updateQuantityWithStockCheck', 
      'saveDeliveryOptions', 
      'setShowStockLoader'
    ]),
    // END BESPOKE GS
    quantityChanged ({ computedQuantity }, orderLine) {
      const payload = {
        Comments: orderLine.comments,
        ProductId: orderLine.productId,
        LineId: orderLine.lineId,
        Quantity: computedQuantity.toString(),
        UnitCode: orderLine.unitCode,
        IsFree: orderLine.isFree
      };      
      // BESPOKE GS-574 / GS-648
      // When updating the quantity use an action that first collects all other orderlines
      // with the same product id so we can map the GS stock check response to all orderlines
      // However if and orderLine has a customFields.orginal_line_number do not perform the GS stock check
      // and use the default way of updating the orderline quantity
      if (orderLine.customFields.original_line_number) {
          payload.CustomFields = orderLine.customFields;
          this.updateOrderLineQuantity([payload]).then(res => {
        });
      } else {
        this.updateQuantityWithStockCheck(payload);
      }
      // END BESPOKE GS-574 / GS-648
    },
    unitsChanged ({ unitCode, quantity, computedQuantity }, orderLine) {
      const payload = {
        Comments: orderLine.comments,
        ProductId: orderLine.productId,
        LineId: orderLine.lineId,
        Quantity: computedQuantity.toString(),
        UnitCode: unitCode,
        IsFree: orderLine.isFree
      };
      this.updateOrderLineQuantity(payload).then(res => {
      });
    },
    promptDelete (orderLine) {
      this.showDeleteProductPrompt = true;
      this.promptPayload = orderLine;
    },
    cancelPrompt () {
      this.showDeleteProductPrompt = false;
      this.promptPayload = null;
    },
    deleteOrderLineFromCart (orderLineId) {
      // BESPOKE GS-574
      // Changed payload to include stockCheck:true 
      this.deleteOrderLine({ orderLineId: orderLineId, stockCheck: true });
      // END BESPOKE GS-574
      this.showDeleteProductPrompt = false;
      this.promptPayload = null;
    },
    cancelEmptyCartPrompt () {
      this.showEmptyCartPrompt = false;
    },
    emptyCart () {
      this.showEmptyCartPrompt = false;
      this.emptyShoppingCart();
    },
    // BESPOKE GS: Added methods to open stock modals
    openStockModal ({ type, id }) {
      window.getProductStockStatus(id, type);
    },
    saveDirectDelivery ({ orderLine, event }) {
      orderLine.customFields.direct_delivery = event.target.checked;

      if (orderLine.customFields.original_line_number) {
          orderLine.LineId = orderLine.lineId;
          this.updateOrderLineQuantity([orderLine]).then(res => {
        });
      } else {
        this.saveDeliveryOptions(orderLine);
      }
    },
    saveLocation ({ orderLine, location }) {
      orderLine.customFields.Location = location;
      orderLine.LineId = orderLine.lineId;
      if (orderLine.customFields.original_line_number) {
          this.updateOrderLineQuantity([orderLine]).then(res => {
        });
      } else {
        this.saveDeliveryOptions(orderLine);
      }
    },
    scrollToTop () {
      document.querySelector('#scroll-anchor-top').scrollIntoView({ 
        behavior: 'smooth',
        block: 'center' 
      });
    },
    scrollToBottom () {
      document.querySelector('#scroll-anchor-bottom').scrollIntoView({ 
        behavior: 'smooth',
        block: 'center'
      });
    }
    // END BESPOKE GS
  },
  updated() {
    let orderLinesTableHeight = $('.orderline-table').height();
    let windowHeight = $(window).height();
    this.showScrollButtons = orderLinesTableHeight > windowHeight;
  },
  // BESPOKE GS
  // Added mounted to change position of the shoppingcart totals
  mounted() {
    $('#ph-totals').append($('#bespoke-totals'));
  },
  // END BESPOKE GS
  created () {
    this.initShoppingCart();

    // BESPOKE GS-574
    // Changed debounce time to 2000
    this.quantityChanged = _.debounce(this.quantityChanged, 1500);
  
    $(window).scroll(()=> {    
      if (this.showScrollButtons) {
        let scrollButton = document.querySelector('.scroll-btn.bottom');
        let scrollButtonInView = false;
        if (scrollButton !== null) {
          scrollButtonInView = scrollButton.getBoundingClientRect().top > 0;
          if (scrollButtonInView) {
            $('#topcontrol').addClass('hidden');
          } else {
            $('#topcontrol').removeClass('hidden');
          }
        }
      } else {
        $('#topcontrol').removeClass('hidden');
      }
    });
    // END BESPOKE GS-574
  }
}
</script>

<style>

</style>
