<template>
  <div class="cmp-sorting-options utlz-row normalize-text mb-sm-3" :class="parentClassRef">
    <div class="utlz-col-md-12">
      <div class="sort-options-wrapper flex-row-wrap justify-content-between align-items-center">
        <p :class="{ invisible: noProductsFound }" class="product-counter" v-translation="{ type: 'label', code: 'label_showing_result', interpolations: [pageRangeString, totalProducts] }"></p>
        <!-- BESPOKE GS: Changed default select box to custom dropdown so it can be styled -->
        <div class="select-pagesize" :class="{ invisible: noProductsFound }">
          <span class="select-label" v-translation="{ type: 'label', code: 'label_show_item_count'}"></span>
          <div class="custom-select-box">
            <div class="current" @click="selectExpanded = !selectExpanded" v-outside-click="{ handler: 'closeDropdownPageSize', exclude: ['dropdownPageSize']}" ref="dropdownPageSize">
              {{ pageSize[selectedIndex] }}
              <i class="fa fa-caret-down"></i>
            </div>
            <ul v-if="selectExpanded" class="options">
              <li v-for="(size, index) in pageSize"
                :key="index"
                @click="changePageSize(index)"
                :class="{ 'selected': selectedIndex === index }"
                class="option">
                {{ pageSize[index] }}
              </li>
            </ul>
          </div>
          <!-- <select v-model="selected" @change="changePageSize()" class="small-tablet">
            <option v-for="page in pageSize" :key="page">{{page}}</option>
          </select> -->
          <!-- END BESPOKE GS -->
        </div>        

        <!-- BESPOKE GS: Changed default select box to custom dropdown so it can be styled -->
        <div :class="{ invisible: noProductsFound }" class="select-sorting select-pagesize" v-if="pageSorting.length > 1">          
          <span class="select-label" v-translation="{ type: 'label', code: 'label_sorting'}"></span>          
          <div class="custom-select-box">
            <div class="current" @click="selectExpandedSorting = !selectExpandedSorting" v-outside-click="{ handler: 'closeDropdownPageSorting', exclude: ['dropdownPageSorting']}" ref="dropdownPageSorting">
              {{ pageSorting[selectedIndexSorting].Description }}
              <i class="fa fa-caret-down"></i>
            </div>
            <ul v-if="selectExpandedSorting" class="options">
              <li v-for="(size, index) in pageSize"
                :key="index"
                @click="changePageSorting(index)"
                :class="{ 'selected': selectedIndexSorting === index }"
                class="option">
                {{ pageSorting[index].Description }}
              </li>
            </ul>
          </div>
          <!-- <select v-model="sorting" @change="changePageSorting()" class="small-tablet">
            <option v-for="sort in pageSorting" :key="sort.Description" :value="sort">{{sort.Description}}</option>
          </select> -->
        </div>
        <!-- END BESPOKE GS -->

        <div class="cmp-slot toggle-filters-slot hidden-lg">
          <slot name="toggle-mobile-filters"></slot>
        </div>

        <!-- BESPOKE GS-514: Added invisible class -->
        <div :class="{ invisible: noProductsFound }" class="cmp-slot pagination-slot hidden-md">
          <!-- END BESPOKE GS-514 -->
          <slot name="pagination"></slot>
        </div>

        <!-- BESPOKE GS -->
        <div class="unique-products">
          <span class="custom-checkbox">
            <input id="unique-products-cb" type="checkbox" v-model="uniqueProducts" @change="ChangeUniqueProducts()" />
            <label for="unique-products-cb" v-translation="{ type: 'label', code: 'label_show_unique'}"></label>
          </span>
        </div>
        <!-- END BESPOKE -->
        
        <!-- BESPOKE GS-514: Add !noProductsFound -->
        <ul :class="{ invisible: noProductsFound }" class="grid-view-btns hidden-md flex-row-nowrap just-space-between">
          <!-- END BESPOKE GS-514 -->
          <li class="blocks">
            <a @click="changeLayout('blocks');" class="button square" :class="{ active: layoutType === 'blocks', 'theme-primary': layoutType === 'blocks' }">
              <i class="fa fa-th"></i>
            </a>
          </li>
          <li class="list">
            <a @click="changeLayout('rows');" class="button square" :class="{ active: layoutType === 'rows', 'theme-primary': layoutType === 'rows'}">
              <i class="fa fa-list"></i>
            </a>
          </li>
        </ul>

      </div>
    </div>
  </div>
</template>

<script>
// BESPOKE GS-514
import { mapGetters, mapState } from 'vuex';
// END BESPOKE GS-514
export default {
  props: {
    layoutType: { type: String, required: false, default: 'blocks' },
    totalProducts: { type: Number, required: true },
    pageSize: { type: Array, required: false, default: () => [32, 64, 128] },
    pageSorting: { type: Array, required: false, default: () => [{ 'Description': 'default', 'Field': '', 'Type': 0 }] },
    pageRange: { type: Object, required: true },
    parentClassRef: { type: String, default: '', required: false },
    uniqueProductsInfo: { type: Boolean, default: false, required: false },
    preselected: {type: Number, required: false}
  },
  data () {
    return {
      selected: '',
      sorting: this.pageSorting[0],
      // BESPOKE GS
      selectExpanded: false,
      selectedIndex: this.preselected,
      selectedIndexSorting: 0,
      selectExpandedSorting: false,
      uniqueProducts: this.uniqueProductsInfo,
      // END BESPOKE GS
    }
  },
  computed: {
    // BESPOKE GS-514: Add mapGetters
    ...mapGetters('elastic', ['noProductsFound']),
    // END BESPOKE GS-514
    pageRangeString () {
      return this.pageRange.from + ' - ' + this.pageRange.to;
    },
    preselectedElement () {
      return this.preselected === undefined ? this.pageSize[0] : this.preselected;
    },
    preselectedIndex () {
      let searhvalue = this.selected;
      return this.pageSize.findIndex(size => size == this.preselected);
    }
  },
  methods: {
    changeLayout (layoutType) {
      this.$emit('changeLayout', layoutType);
    },
    // BESPOKE GS
    // Changed events to handle custom select dropdowns
    changePageSorting (index) {
      this.$emit('changePageSorting', this.pageSorting[index]);
      this.selectedIndexSorting = index;
      this.closeDropdownPageSorting();
    },
    changePageSize (index) {
      this.$emit('changePageSize', parseInt(this.pageSize[index]));
      this.selectedIndex = index;
      this.closeDropdownPageSize();
      // END BESPOKE GS
    },
    //BESPOKE
    ChangeUniqueProducts () {
      this.$emit('changeUniqueProducts', this.uniqueProducts);
    },
    // END BESPOKE
    // BESPOKE GS
    closeDropdownPageSize () {
      this.selectExpanded = false;
    },
    closeDropdownPageSorting () {
      this.selectExpandedSorting = false;
    }
    // END BESPOKE GS
  }
}
</script>

<style>

</style>
