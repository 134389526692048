import axios from 'axios';
import { Product } from 'src/models/product.model';
import { OrderLine } from 'src/models/order-line.model';
import { formatPrice } from 'src/utilities/helpers';

// BESPOKE GS
import { getProductInformation } from 'src/services/product-service';
// END BESPOKE GS

// BESPOKE GS
// Added global variable to store setTimeout that we can clear
let gsStockCheckTimer;
// END BESPOKE GS

const state = {
  products: [],
  orderLines: [],
  productCodes: [],
  totalProductsCount: 0,
  shoppingCartInitizalized: false,
  loadingOrderLines: false,
  validatingStock: false,
  shoppingCartTotals: {
    CouponsTax: { price: '€ 0,00', rawPrice: 0 },
    CouponsNet: { price: '€ 0,00', rawPrice: 0 },
    CouponsTotal: { price: '€ 0,00', rawPrice: 0 },
    OrderCostsTax: { price: '€ 0,00', rawPrice: 0 },
    OrderCostsNet: { price: '€ 0,00', rawPrice: 0 },
    OrderCostsTotal: { price: '€ 0,00', rawPrice: 0 },
    ShippingCostsTax: { price: '€ 0,00', rawPrice: 0 },
    ShippingCostsNet: { price: '€ 0,00', rawPrice: 0 },
    ShippingCosts: { price: '€ 0,00', rawPrice: 0 },
    OrderLinesTotalTax: { price: '€ 0,00', rawPrice: 0 },
    OrderLinesTotalNet: { price: '€ 0,00', rawPrice: 0 },
    OrderLinesTotal: { price: '€ 0,00', rawPrice: 0 },
    OrderTotal: { price: '€ 0,00', rawPrice: 0 }
  },
  shoppingCartValid: false,
  shoppingCartEmpty: false,
  minimumOrderQuantityPrice: '€ 0,00',
  minimumOrderQuantityRawPrice: 0,
  orderLinesTotalPrice: '€ 0, 00',
  orderLinesTotalRawPrice: 0,
  invalidOrderLines: [],
  totalOrderLines: 0,
  stockValid: false,
  couponCode: '',
  hasCoupon: false,
  shoppingCartTotalsFormat: [],
  shoppingCartControlTotalsFormat: [],
  // BESPOKE GS
  // Added extra state
  checkingGsStock: false,
  initialStockChecked: false,
  showStockLoader: false,
  bespokeConfig: {},
  scannedProducts: []
  // END BESPOKE
};

const getters = {
  productCodes (state) {
    return state.productCodes;
  },
  orderLines (state) {
    return state.orderLines;
  },
  totalProductsCount (state) {
    return state.totalProductsCount;
  },
  loadingOrderLines (state) {
    return state.loadingOrderLines;
  },
  validatingStock (state) {
    return state.validatingStock;
  },
  shoppingCartInitizalized (state) {
    return state.shoppingCartInitizalized;
  },
  productInformationEndpoint (state, getters, rootState, rootGetters) {
    const endpoint = rootGetters.productInformationEndpoint;
    const client = rootGetters.clientCode;
    const language = rootGetters.language;
    return `${endpoint}/${client}?language=${language}`;
  },
  shoppingCartTotals (state) {
    return state.shoppingCartTotals;
  },
  shoppingCartTotalsFormat (state) {
    return state.shoppingCartTotalsFormat;
  },
  shoppingCartControlTotalsFormat (state) {
    return state.shoppingCartControlTotalsFormat;
  },
  stockValid (state, getters, rootState, rootGetters) {
    if (!rootGetters.stockLimit) {
      return true;
    } else {
      return state.stockValid;
    }
  },
  invalidOrderLines (state) {
    return state.invalidOrderLines;
  },
  shoppingCartEmpty (state) {
    return state.shoppingCartEmpty;
  },
  orderLinesTotal (state) {
    return state.shoppingCartTotals.OrderLinesTotal;
  },
  minimumOrderQuantity (state) {
    return state.minimumOrderQuantity;
  },
  minimumOrderQuantityPrice (state) {
    return state.minimumOrderQuantityPrice;
  },
  minimumOrderQuantityRawPrice (state) {
    return state.minimumOrderQuantityRawPrice;
  },
  orderLinesTotalPrice (state) {
    return state.orderLinesTotalPrice;
  },
  orderLinesTotalRawPrice (state) {
    return state.orderLinesTotalRawPrice;
  },
  minimumOrderQuantityValid (state, getters, rootState, rootGetters) {
    if (rootGetters.useMinimumOrderQuantity) {
      return getters.orderLinesTotalRawPrice - getters.minimumOrderQuantityRawPrice > 0;
    } else {
      return true;
    }
  },
  couponCode (state) {
    return state.couponCode;
  },
  hasCoupon (state) {
    return state.hasCoupon;
  },
  showCheckoutButton (state, getters) {
    return getters.stockValid && getters.minimumOrderQuantityValid;
  },
  // BESPOKE GS
  checkingGsStock (state) {
    return state.checkingGsStock;
  },
  initialStockChecked (state) {
    return state.initialStockChecked;
  },
  showStockLoader (state) {
    return state.showStockLoader;
  },
  scannedProducts (state) {
    return state.scannedProducts;
  },
  elasticEndpoint (state, rootState, rootGetters, { resultsSize, prodCodeQuery }) {
    
  },
  // END BESPOKE GS
};

const mutations = {
  initConfig (state, config) {
    state.shoppingCartTotalsFormat = config.shoppingCartTotalsFormat;
    state.shoppingCartControlTotalsFormat = config.shoppingCartControlTotalsFormat;
  },
  // BESPOKE GS
  // Initialize the store with a bespokeConfig, so we can 
  // use it to retrieve products from elastic with the scantool
  initBespokeConfig (state, config) {
    state.bespokeConfig = config;
  },
  // END BESPOKE GS
  setProductCodes (state, productCodes) {
    state.productCodes = productCodes;
  },
  setProducts (state, products) {
    const orderLines = [...state.orderLines];
    orderLines.forEach(orderLine => {
      let product = products.filter(product => product.id === orderLine.productId);
      if (product.length > 0) {
        orderLine.setProduct(product[0]);
        orderLine.setComputedQuantity();        
      }      
    });
    state.orderLines = orderLines.filter((x) => x.product != null)
  },
  setOrderLines (state, orderLines) {
    state.orderLines = orderLines;
    state.totalOrderLines = orderLines.length;
  },
  setLoadingOrderLines (state, loading) {
    state.loadingOrderLines = loading;
  },
  setValidatingStock (state, validating) {
    state.validatingStock = validating;
  },
  addToCart (state, productRow) {
    state.shoppingCartRows.push(productRow);
  },
  setTotalAndCount (state, totalProducts) {
    state.totalProductsCount = totalProducts;
  },
  setShoppingCartEmpty (state, empty) {
    state.shoppingCartEmpty = empty;
  },
  setProductPrice (state, productWithPrice) {
    state.orderLines.forEach((orderLine) => {
      if (orderLine.productId === productWithPrice.id) {
        orderLine.product.prices = productWithPrice.prices;
        orderLine.setOrderLineTotal();
      }
    });
  },
  setProductStock (state, productWithStock) {
    state.orderLines.forEach(orderLine => {
      if (orderLine.productId === productWithStock.id) {
        orderLine.product.setStock(productWithStock.stock);
        orderLine.setComputedQuantity();
      }
    });
  },
  updateOrderLineQuantity (state, { lineId, quantity, unitCode, totalCount, showPrices }) {
    let index = state.orderLines.findIndex(orderLine => orderLine.lineId === lineId);
    state.orderLines[index].quantity = quantity;
    state.orderLines[index].unitCode = unitCode;

    // update total orderline quantities
    let prodCode = state.orderLines[index].productId;
    state.orderLines.forEach(orderLine => {
      if (orderLine.productId === prodCode) {
        orderLine.totalOrderLinesQuantity = totalCount;
      }
    });
    if (showPrices) {
      state.orderLines[index].setOrderLineTotal();
    }
    state.orderLines[index].setComputedQuantity();
  },
  updateOrderLineComment(state, { lineId, comment }) {
    let index = state.orderLines.findIndex(orderLine => orderLine.lineId === lineId);
    state.orderLines[index].comments = comment;
  },
  deleteOrderLine(state, { orderLineIndex, totalCount }) {
    const orderLines = [...state.orderLines];
    let prodCode = state.orderLines[orderLineIndex].productId;
    state.orderLines.forEach(orderLine => {
      if (orderLine.productId === prodCode) {
        orderLine.totalOrderLinesQuantity = totalCount;
      }
    });
    orderLines.splice(orderLineIndex, 1);
    state.orderLines = orderLines;
  },
  setShoppingCartTotals (state, totals) {
    let totalsObj = {};
    Object.keys(totals).forEach(key => {
      totalsObj[key] = { price: formatPrice(totals[key]), rawPrice: totals[key] };
    });
    state.shoppingCartTotals = totalsObj;
    state.minimumOrderQuantityPrice = formatPrice(totals.MinimumOrderQuantity);
    state.minimumOrderQuantityRawPrice = totals.MinimumOrderQuantity;
    state.orderLinesTotalPrice = formatPrice(totals.OrderLinesTotal);
    state.orderLinesTotalRawPrice = totals.OrderLinesTotal;
  },
  setCouponCode (state, { CouponCode, HasCoupon }) {
    state.couponCode = CouponCode;
    state.hasCoupon = HasCoupon;
  },
  setShoppingCartInitizalized (state, intitialized) {
    state.shoppingCartInitizalized = intitialized;
  },
  checkStockState (state) {
    const orderLines = [...state.orderLines];
    let invalidLines = [];
    orderLines.forEach(orderLine => {
      if (orderLine.product.stock.stockTotal < orderLine.totalOrderLinesQuantity) {
        invalidLines.push(orderLine);
      }
    });
    if (invalidLines.length) {
      state.stockValid = false;
      state.invalidOrderLines = invalidLines;
    } else {
      state.stockValid = true;
      state.invalidOrderLines = [];
    }
  },
  emptyShoppingCart (state) {
    state.orderLines = [];
  },
  // BESPOKE GS
  // Adds the custom fields response to the orderLine
  // which have properties to determine the orderline state
  updateCustomFields (state, stockLine) {
    state.orderLines.forEach(orderLine => {
      if (orderLine.lineId === stockLine.LineId) {
        orderLine.customFields.warehouse = stockLine.WarehouseId;
        orderLine.customFields.Location = stockLine.Location;
        orderLine.customFields.direct_delivery = stockLine.DirectDelivery;
        orderLine.customFields.StockStatus = stockLine.StockStatus;
      }
    });
  },
  setCheckingGsStock (state, checking) {
    state.checkingGsStock = checking;
  },
  setInitialStockChecked (state, checked) {
    state.initialStockChecked = checked;
  },
  setShowStockLoader (state, show) {
    state.showStockLoader = show;
    if (!show) {
      clearTimeout(gsStockCheckTimer);
    }
  },
  addOrderLine (state, orderLine) {
    state.orderLines.unshift(orderLine);
  },
  setScannedProducts (state, scannedProducts) {
    state.scannedProducts = scannedProducts;
  },
  clearScannedProducts (state) {
    state.scannedProducts = [];
  }
  // END BESPOKE GS
}

const actions = {
  initShoppingCart ({ getters, commit, dispatch }) {
    dispatch('getOrderLines');
    commit('setShoppingCartInitizalized', true);
  },
  initShoppingCartControl ({ dispatch }) {    
    dispatch('getOrderLinesShoppingCartControl');
  },
  getShoppingCartTotals ({ commit, rootGetters, dispatch }) {
    axios.post(rootGetters.shoppingCartTotalsEndpoint, {})
      .then(res => {
        const data = res.data.d.Totals;
        commit('setShoppingCartTotals', data.Prices);
        dispatch('renderCheckoutButton');
        if (rootGetters.useCoupons) {
          commit('setCouponCode', data.CouponInfo);
        }
      });
  },
  getShoppingCartTotalAndCount ({ commit, rootGetters }) {
    axios.post(rootGetters.shoppingCartTotalAndCountEndpoint, {})
      .then(res => {
        const data = res.data.d;
        if (data.Status === 'Success') {
          commit('setTotalAndCount', data.Totals.ProductCount);
        } else {
          // TODO ERROR HANDLING
        }
      });
  },
  getOrderLines ({ commit, dispatch, getters, rootGetters }) {
    commit('setLoadingOrderLines', true);
    commit('setOrderLines', []);
    axios.post(rootGetters.orderLinesEndpoint, {})
      .then(res => {
        const data = res.data.d;
        if (data.Status === 'Success') {
          dispatch('getShoppingCartTotals');
          if (data.Lines.length) {
            const productCodes = data.Lines.map(orderLine => orderLine.ProductId);
            commit('setShoppingCartEmpty', false);
            commit('setProductCodes', productCodes);
            let lines = data.Lines.map(orderLine => new OrderLine(orderLine));
            commit('setOrderLines', lines);

            dispatch('getProductInformation');
          } else {
            commit('setShoppingCartEmpty', true);
            dispatch('renderCheckoutButton');
            // BESPOKE GS
            // Set focus on the barcode scanner on initial pageload, but only if there
            // are no orderlines. If there are orderlines, focus is set after the initial stock check
          $('.barcode-input').focus();
            // END BESPOKE GS 
          }          
        } else if (data.Status === 'Failure') {
          dispatch('renderCheckoutButton');
          window.updateErrorMessage(data.ErrorMessage);
        }
        commit('setLoadingOrderLines', false);
      });
  },
  getOrderLinesShoppingCartControl ({ commit, dispatch, rootGetters }) {
    commit('setLoadingOrderLines', true);
    axios.post(rootGetters.orderLinesEndpoint, {})
      .then(res => {
        const data = res.data.d;
        if (data.Status === 'Success') {
          dispatch('getShoppingCartTotals');
          if (data.Lines.length) {
            const productCodes = data.Lines.map(orderLine => orderLine.ProductId);
            commit('setShoppingCartEmpty', false);
            commit('setProductCodes', productCodes);
            commit('setOrderLines', data.Lines.map(orderLine => new OrderLine(orderLine)));
            dispatch('getProductInformation', false);
          } else {
            commit('setShoppingCartEmpty', true);
          }
        } else if (data.Status === 'Failure') {
          window.updateErrorMessage(data.ErrorMessage);
        }
        commit('setLoadingOrderLines', false);
      });
  },
  // BESPOKE GS
  doStockCheckOrderlines ({ dispatch, getters }) {
    const orderLinesObj = getters.orderLines.map(orderLine => {
      return { orderLine: orderLine, newQuantity: null };
    });
    dispatch('doStockCheck', orderLinesObj);
  },
  saveDeliveryOptions ({ getters, dispatch }, product) {
    const orderLinesObj = []; 
    getters.orderLines.forEach(orderLine => {
      if (orderLine.productId === product.productId) {
        orderLinesObj.push({ orderLine: orderLine, newQuantity: null });
      }
    });
    dispatch('doStockCheck', orderLinesObj);
  },
  setOrderLinesDirectDelivery ({ getters, dispatch }, directDeliveryEv) {
    const orderLines = [...getters.orderLines];
    const orderLinesStockCheck = [];
    const orderLinesWithoutStockCheck = [];

    const directDelivery = directDeliveryEv.target.checked;

    orderLines.forEach(orderLine => {      
      
      orderLine.customFields.direct_delivery = directDelivery;
      // Orderlines with a customField of original_line_number are not updated via
      // the stock check but through the standard updateQuantity
      if (orderLine.customFields.original_line_number) {
        orderLine.LineId = orderLine.lineId;
        orderLinesWithoutStockCheck.push(orderLine);
      } else {
        orderLinesStockCheck.push({ orderLine: orderLine, newQuantity: null });
      }
    });

    if (orderLinesWithoutStockCheck.length) {
      dispatch('updateOrderLineQuantity', orderLinesWithoutStockCheck);
    }
    if (orderLinesStockCheck.length) {
      dispatch('doStockCheck', orderLinesStockCheck);
    }
  },
  // Takes an array of objects with orderlines and it's corresponding new
  // quantity which can be null if there is no new quantity
  doStockCheck ({ commit, dispatch, rootGetters, getters }, orderLinesArr) {
    commit('setCheckingGsStock', true);

    // Set timer that will change state if doStockCheck takes longer than 8 seconds
    gsStockCheckTimer = setTimeout(()=> { 
      commit('setShowStockLoader', true);
    }, 8000);

    let requestLines = [];
    orderLinesArr.forEach(function (line) {
      if (!line.orderLine.customFields.original_line_number) {
        let obj = {
          ProductId: line.orderLine.productId,
          Quantity: line.orderLine.quantity,
          SerialNumber: '',
          DirectDelivery: line.orderLine.customFields.direct_delivery,
          LineId: line.orderLine.lineId,
          Location: line.orderLine.customFields.Location !== null && line.orderLine.customFields.Location !== undefined ? line.orderLine.customFields.Location : '',
          WarehouseId: line.orderLine.customFields.warehouse !== null && line.orderLine.customFields.warehouse !== undefined ? line.orderLine.customFields.warehouse : '',
          StockControl: line.orderLine.product.customBooleans.STOCK_CONTROL,
          customFields: line.orderLine.customFields
        };
        requestLines.push(obj);
      }      
    });
    if (requestLines.length) {
      // Returns updated stock object from the GS API    
      axios.post(rootGetters.bespokeEndpoints.shoppingCartUrl + "/doStockCheck", { OriginalLines: requestLines })
      .then(res => {

        if (!getters.initialStockChecked) {          
          commit('setInitialStockChecked', true);
        }
        commit('setCheckingGsStock', false);
        commit('setShowStockLoader', false);        

        const responseLines = res.data.d;
        const orderLinesToBeUpdatedArr = [];
        responseLines.forEach(responseLine => {    
          // Update each orderLine with the returned customFields
          commit('updateCustomFields', responseLine);
          
          let filteredLine = orderLinesArr.filter(lineObj => lineObj.orderLine.lineId === responseLine.LineId)[0];
          let quantity = filteredLine.newQuantity !== null ? filteredLine.newQuantity : filteredLine.orderLine.quantity;
          const orderLineToBeUpdated = {
            Comments: filteredLine.orderLine.comments,
            IsFree: filteredLine.orderLine.isFree,
            LineId: responseLine.LineId,
            ProductId: responseLine.ProductId,
            Quantity: parseInt(quantity),
            UnitCode: filteredLine.orderLine.unitCode,
            CustomFields: filteredLine.orderLine.customFields
          }

          if (!filteredLine.orderLine.customFields.hasOwnProperty("direct_delivery")) {
            dispatch("doStockCheckOrderlines")
          }

          // Update orderline quantities with new quantities
          // based on returned stock status from the GS API
          // Sometimes there is no difference in new quanties
          // but we update anyway to update the customFields object
          orderLineToBeUpdated.Quantity = responseLine.Quantity;
          orderLinesToBeUpdatedArr.push(orderLineToBeUpdated);
        });          
        if (orderLinesToBeUpdatedArr.length > 0) {
          dispatch('updateOrderLineQuantity', orderLinesToBeUpdatedArr);
        }
      })
    } else {
      commit('setCheckingGsStock', false);
      commit('setShowStockLoader', false);  
    }
  },
  setShowStockLoader ({ commit }, loading) {
    commit('setShowStockLoader', loading);
  },
  // END BESPOKE
  getProductInformation ({ getters, rootGetters, commit, dispatch }, checkStock = true) {
    axios.post(getters.productInformationEndpoint, getters.productCodes)
      .then(res => {
        const products = res.data.map(product => new Product(product));
        commit('setProducts', products);

        
          // BESPOKE GS
          // Do an initial GS stock check for all orderlines, but only once in pageload
          const shoppingCartOverviewLoaded = window.vue.modules.shoppingCartOverview;
          if (!getters.initialStockChecked && shoppingCartOverviewLoaded) {            
            dispatch('doStockCheckOrderlines');
          }
          // END BESPOKE GS

        if (rootGetters.showStock && checkStock) {
          dispatch('getProductStock', products);
        } else {
          products.forEach(product => { product.setStock({ stockTotal: 0 }); })
        }

        if (rootGetters.showPrices) {
          dispatch('getProductPrices', products);
        }
      });
  },
  validateShoppingCartStock ({ commit, getters, rootGetters, dispatch }) {
    commit('setValidatingStock', true);
    let counter = 0; let orderLinesLength = getters.orderLines.length;
    const products = getters.orderLines.map(orderLine => orderLine.product);
    products.forEach(product => {
      axios.post(rootGetters.productStockEndpoint, { 'productCode': product.id })
        .then(res => {
          counter++;
          product.setStock(res.data.d);
          commit('setProductStock', product);
          if (counter === orderLinesLength && rootGetters.stockLimit) {
            commit('checkStockState');
            if (getters.invalidOrderLines.length) {
              const message = window.vue.translations.message.message_invalid_stock_orderlines;
              const offset = window.$('#stock-warning').offset().top - 75;
              window.updateErrorMessage(message);
              window.scrollTo({ top: offset, behavior: 'smooth' });
              dispatch('renderCheckoutButton');
            } else {
              dispatch('checkout');
            }
            commit('setValidatingStock', false);
          }
        });
    });
  },
  getProductStock ({ rootGetters, getters, commit, dispatch }, products) {
    let counter = 0; let orderLinesLength = getters.orderLines.length;
    products.forEach(product => {
      axios.post(rootGetters.productStockEndpoint, { 'productCode': product.id })
        .then(res => {
          counter++;
          product.setStock(res.data.d);
          commit('setProductStock', product);
          if (counter === orderLinesLength && rootGetters.stockLimit) {
            commit('checkStockState');
            dispatch('renderCheckoutButton');
          }
        });
    });
  },
  getProductPrices ({ commit, getters, dispatch, rootGetters }, products) {
    let priceRequestWrapper = {};
    priceRequestWrapper.CustomerId = rootGetters.userLoggedOn ? rootGetters.customerId : '';
    priceRequestWrapper.Pricelist = rootGetters.customerPriceList;
    priceRequestWrapper.Products = products.map(prod => {
      let ret = {};
      ret.ProductId = prod.id;
      ret.ProductGroup = prod.discountGroup;

      ret.ProductUnit = '';
      if (prod.units !== undefined && prod.units !== null) {
        ret.ProductUnit = prod.units.length > 0 ? prod.units[0].code : '';
      }
      return ret;
    });

    let endpoint = rootGetters.productPriceEndpoint + 'prices/' + rootGetters.clientCode

    axios.post(endpoint, priceRequestWrapper)
      .then(res => {
        res.data.forEach(price => {
          let product = products[products.findIndex(x => x.id === price.productId)];

          const pricesObj = [];
          Object.keys(price.volumes).forEach(key => {
            pricesObj.push({
              price: price.volumes[key].price,
              basePrice: price.volumes[key].basePrice,
              isSalesAction: price.volumes[key].isSalesAction,
              quantity: Math.round(parseInt(key))
            });
          });

          product.setPrices(pricesObj);
          commit('setProductPrice', product);
        });
      });
  },
  // BESPOKE GS-609
  // Changed endpoint to UpdateMultipleOrderLinesEndpoint
  updateOrderLineQuantity ({ rootGetters, getters, commit, dispatch }, orderLinesArr) {

    // BESPOKE GS-744
    // Also add the ProductGroup to the orderlineDto
    orderLinesArr.forEach(orderLine => {
      let groupCode = getters.orderLines.filter(line => orderLine.LineId === line.lineId)[0].product.discountGroup;
      orderLine.ProductGroup = groupCode;
    });
    // END BESPOKE GS-744

    return new Promise((resolve, reject) => {
      axios.post(rootGetters.UpdateMultipleOrderLinesEndpoint, { lines: orderLinesArr })
        .then(res => {
          const data = res.data.d;

          if (data.Status === 'Success') {
            data.UpdatedLines.forEach(updatedLine => {
              commit('updateOrderLineQuantity', {
                lineId: updatedLine.LineId,
                quantity: parseInt(updatedLine.Quantity),
                unitCode: updatedLine.UnitCode,
                totalCount: updatedLine.TotalCount,
                showPrices: rootGetters.showPrices
              });
            });
            
            commit('setShoppingCartTotals', data.Totals.Prices);
            dispatch('getShoppingCartTotalAndCount');

            if (rootGetters.stockLimit) {
              commit('checkStockState');
            }

            dispatch('renderCheckoutButton');

            resolve(true);
          } else {
            reject();
            window.updateErrorMessage(data.ErrorMessage);
            dispatch('getOrderLines');
          }
        });
    });
  },
  // END BESPOKE GS-609

  // BESPOKE GS-574
  // Check stock for lines that have an updated quantity
  updateQuantityWithStockCheck ({ getters, commit, dispatch }, updatedOrderLine) {
    const orderLinesObj = []; 
    getters.orderLines.forEach(orderLine => {
      if (orderLine.productId === updatedOrderLine.ProductId) {
        if (updatedOrderLine.LineId === orderLine.lineId) {
          orderLine.quantity = parseInt(updatedOrderLine.Quantity);
        }
        orderLinesObj.push({ orderLine: orderLine, newQuantity: null });
      }
    });
    dispatch('doStockCheck', orderLinesObj);
  },
  // END BESPOKE GS
  updateOrderLineComment ({ rootGetters, getters, commit, dispatch }, orderLine) {
//BESPOKE GS-708, GS-710
    const updatedOrderLine = getters.orderLines.filter(line => line.lineId === orderLine.LineId)[0];
    updatedOrderLine.comments = orderLine.Comments;
    if (!updatedOrderLine.customFields.original_line_number) {
      dispatch('doStockCheck', [{ orderLine: updatedOrderLine, newQuantity: null }]);
    } else {
      return new Promise((resolve, reject) => {
          axios.post(rootGetters.updateOrderLineEndpoint, { orderLineToBeUpdatedObj: updatedOrderLine })
            .then(res => {
              const data = res.data.d;
              if (data.Status === 'Success') {
                commit('updateOrderLineComment', { lineId: data.UpdatedLine.LineId, comment: data.UpdatedLine.Comments });
                resolve();
              } else {
                reject();
                window.updateErrorMessage(data.ErrorMessage);
              }
            });
        });
    }

    

    // return new Promise((resolve, reject) => {
    //   axios.post(rootGetters.updateOrderLineEndpoint, { orderLineToBeUpdatedObj: orderLine })
    //     .then(res => {
    //       const data = res.data.d;
    //       if (data.Status === 'Success') {
    //         commit('updateOrderLineComment', { lineId: data.UpdatedLine.LineId, comment: data.UpdatedLine.Comments });
    //         resolve();
    //       } else {
    //         reject();
    //         window.updateErrorMessage(data.ErrorMessage);
    //       }
    //     });
    // });
    //END BESPOKE
  },
  // BESPOKE GS-574
  // Added parameter property stockCheck
  deleteOrderLine ({ rootGetters, getters, commit, dispatch }, { orderLineId, stockCheck }) {
    const orderLineIndex = getters.orderLines.findIndex(orderLine => orderLine.lineId === orderLineId);
    const orderLine = getters.orderLines[orderLineIndex];
    const payload = {
      LineId: orderLine.lineId,
      ShoppingCartId: orderLine.shoppingCartId,
      ProductId: orderLine.productId,
      Comments: orderLine.comments,
      Quantity: orderLine.quantity,
      UnitCode: orderLine.unitCode,
      IsFree: orderLine.isFree
    }    

    axios.post(rootGetters.deleteOrderLineEndpoint, { lineToBeDeletedObj: payload }).then(res => {
      const data = res.data.d;
      if (data.Status === 'Success') {
        commit('deleteOrderLine', {
          orderLineIndex: orderLineIndex,
          totalCount: data.LineToBeDeleted.TotalCount
        });
        commit('setTotalAndCount', data.TotalsCount.ProductCount);
        commit('setShoppingCartTotals', data.Totals.Prices);

        // BESPOKE GS-574
        // After deleting a product retrieve other orderlines 
        // with similar product id's need to do another stock checkalert(stockCheck)
        if (stockCheck) {
          const orderLinesObj = []; 
          getters.orderLines.forEach(line => {
            if (line.productId === orderLine.productId) {
              orderLinesObj.push({ orderLine: line, newQuantity: null })
            }
          });
          if (orderLinesObj.length) {
            dispatch('doStockCheck', orderLinesObj);
          }
        }        

        if (rootGetters.stockLimit) {
          commit('checkStockState');
          dispatch('renderCheckoutButton');
        }
        if (getters.orderLines.length === 0) {
          commit('setShoppingCartEmpty', true);
        }
      } else if (data.Status === 'Failure') {
        window.updateErrorMessage(data.ErrorMessage);
      }
    });
  },
  applyCoupon ({ rootGetters, commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(rootGetters.addCouponEndpoint, { couponCode: payload })
        .then(res => {
          const response = res.data.d;
          const status = response.Status;
          if (status === 'Success') {
            commit('setCouponCode', { CouponCode: payload, HasCoupon: true });
            commit('setShoppingCartTotals', response.Totals.Prices);
            resolve(response.Message);
          } else if (status === 'CouponUsed' || status === 'NotFound') {
            commit('setCouponCode', { CouponCode: '', HasCoupon: false });
            reject(response.Message);
          }
        });
    });
  },
  removeCoupon ({ rootGetters, getters, commit }) {
    return new Promise((resolve, reject) => {
      axios.post(rootGetters.removeCouponEndpoint, { couponCode: getters.couponCode })
        .then(res => {
          const response = res.data.d;
          if (response.Status === 'Success') {
            commit('setCouponCode', { CouponCode: '', HasCoupon: false });
            commit('setShoppingCartTotals', response.Totals.Prices);
            resolve(response.Message);
          } else if (response.Status === 'Failure') {
            window.updateErrorMessage(response.Message);
            reject();
          }
        });
    });
  },
  emptyShoppingCart ({ rootGetters, commit }) {
    axios.post(rootGetters.emptyShoppingCartEndpoint, {}).then(res => {
      const data = res.data.d;
      if (data.Status === 'Success') {
        commit('emptyShoppingCart');
        commit('setShoppingCartEmpty', true);
        window.updateOrderMessage(data.Message);
      } else if (data.Status === 'Failure') {
        window.updateErrorMessage(data.Message);
      }
    });
  },
  renderCheckoutButton ({ getters }) {
    if (!getters.showCheckoutButton) {
      window.$('.checkout-button').addClass('disabled');
    } else {
      window.$('.checkout-button').removeClass('disabled');
    }
  },
  hidePageElements () {
    window.$('.vuejs-hook').hide();
  },
  checkout () {
    window.$('.uc_payment .inputbutton').click();
  },
  addToCart ({ rootGetters, getters, commit, dispatch }, payload) {
    let endpoint = rootGetters.orderProductEndpoint;
    let userLoggedOn = rootGetters.userLoggedOn;
    const products = Array.isArray(payload) ? payload : [payload];
    products.forEach((x) => {
      if (x['warehouse'] === "" || x['warehouse'] === undefined) { x['warehouse'] = "1" }
      if (x['Location'] === undefined) { x['Location'] = "" }
      if (x['direct_delivery'] === undefined) { x['direct_delivery'] = false }
    });
    return new Promise((resolve, reject) => {
      axios.post(endpoint, JSON.stringify({ products: products, loggedInStatus: userLoggedOn })).then(
        res => {
          const response = res.data.d;
          if (response.errorMessages) {
            // BESPOKE GS
            resolve(false);
            // END BESPOKE GS
            if (response.errorMessages === 'log in status changed') {
              window.location.replace('/webshop/login.aspx?RedirectUrl=' + window.location.pathname + window.location.search);
            } else {
              window.updateErrorMessage(response.errorMessages);
            }
          } else {
            // BESPOKE GS-683
            // If a product is added in the shoppingcart overview, use different actions 
            // than usually since we need to update the orderlines overview directly
            if (getters.shoppingCartInitizalized) {    
              const orderLine = new OrderLine(response.newline.OrderLine);              
              dispatch('addOrderLine', orderLine);
              commit('clearScannedProducts');
              resolve(true);
            } else {              
              dispatch('getShoppingCartTotalAndCount')
              resolve(true);
            }
            // END BESPOKE GS-683

            // REFERS TO FUNCTION CALLS IN CUSTOM.JS
            if (response.errorMessages) {
              window.updateErrorMessage(response.errorMessages);
            }

            var message = response.order_message;
            if (response.totalProductsAdded > 0) {
              window.updateOrderMessage(message);
            }
            // END CUSTOM.JS FUNCTION CALLS
          }
        })
        .catch(err => {
          // @TODO Error handling
          reject(err);
        });
    });
  },
  // BESPOKE GS
  // Retrieves scanned products from elastic
  // To alter the elatic endpoint properties they may be passed in the bespokeConfig in uc_vuejs_shopping_cart_overview,
  // these properties are then accessible via the state.bespokeConfig object
  getScannedProducts ({ getters, rootState, rootGetters, commit }, prodCodeQuery) {
    return new Promise((resolve, reject) => {
      if (prodCodeQuery !== "") {
    
        const endpoint = rootGetters.elasticProductsEndpoint;
        const from = 0;
        const language = rootGetters.language;
        const size = rootGetters.bespokeGlobalWs.scantoolResultsSize;
        const aggregations = false;
        const customerListId = rootGetters['elastic/customerListId'];
        const query = prodCodeQuery;
        const customerId = rootGetters.userLoggedOn ? rootGetters.customerId : '';
        const pricingQueryString = state.bespokeConfig.pricingQueryString;
        const client = rootGetters.clientCode;
        const priceListCode = rootGetters.customerPriceList;
        const groupProducts = rootGetters.groupProducts;
        const sort = 'customDecimals.PROD_PRIO|1'; // We use a hardcoded sort argument since in the elastic store we also only use the first value of the sortingOptions
        var sortingstring = '';

        if (sort !== null) sortingstring = '&sort=' + sort;

        const elasticEndpoint = `${endpoint}?lang=${language}${sortingstring}&from=${from}&size=${size}&aggr=${aggregations}&query=${query}&customerId=${customerId}&customerPricelist=${priceListCode}&listId=${customerListId}&client=${client}&pricingQuerystring=${pricingQueryString}&groupProducts=${groupProducts}`;

        const searchConfig = {
          Category: null,
          ExcludeCategories: [],
          ExcludeLabels: [],
          ExtraQueries: [],
          IncludeLabels: [],
          NumericAggregations: null,
          NumericProperties: null,
          ShouldIncludeLabels: [],
          StringAggregations: [],
          StringProperties: {},
          UserId: null
        };

        axios.post(elasticEndpoint, searchConfig).then(prodCodeRes => {
          const prodCodesRes = prodCodeRes.data.products;
          if (prodCodesRes !== null) {
            if (prodCodesRes.length === 1 && prodCodesRes[0].key === prodCodeQuery.trim().toUpperCase()) {
              resolve('foundExactProduct');
            }
            else {
              const filteredCodes = prodCodeRes.data.products.map(code => code.value[0]);
  
              if (filteredCodes.length) {
                const config = { prodCodes: filteredCodes, getStock: false, getPrices: false, getCustomerLists: false, getSeoInformation: false };
                
                getProductInformation(config).then(res => {
                  const products = res.products;
                  if (products.length) {
                    resolve('foundProducts');
                    commit('setScannedProducts', products);
                  }       
                });
              } else {
                resolve('noProductsFound');
              }
            }
          }
          else {
            commit('clearScannedProducts');
            resolve('noProductsFound');
          }               
        });      
      }
    });    
  },
  addOrderLine ({ getters, commit, dispatch }, orderLine) {
    getProductInformation({ prodCodes: [orderLine.productId], getStock: false }).then(orderLineRes => {
      orderLineRes.retrievedPrices.then(pricesRetrieved => {
        if (pricesRetrieved) {
          orderLine.setProduct(orderLineRes.products[0]);                  
          orderLine.setOrderLineTotal();
          commit('addOrderLine', orderLine);
          commit('setShoppingCartEmpty', false);
          dispatch('getShoppingCartTotals');
          dispatch('getShoppingCartTotalAndCount');
          
          const orderLinesObj = []; 
          getters.orderLines.forEach(line => {
            if (line.productId === orderLine.productId) {
              orderLinesObj.push({ orderLine: line, newQuantity: null });
            }
          });
          dispatch('doStockCheck', orderLinesObj);
        }                  
      });
    });          
  }
  // END BESPOKE GS
};

export default {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations
};
