<template>
  <div class="cmp-order-line-comment cmp-location-search-bar">
      <div class="flex-row-nowrap" :class="{ 'editing': editing }">
        <div class="input-wrapper">
          <input type="text"
            readonly
            ref="orderLineLocation"
            :placeholder="placeholder"
            @focus="edit()"
            @blur="toggleEditing()"
            @change="saveLocationEv()"
            v-model="currentLocation"
            class="form-field text-theme-primary flex-sm-grow-1"
          />
          <i class="fa fa-caret-down location-arrow-down"></i>
          <div v-if="editing" class="location-suggestions">
            <ul>
              <li class="loading-suggestions" v-if="loadingSuggestions">
                <span class="loader"></span>
                <span v-translation="{ type: 'label', code: 'label_loading_suggestions' }"></span>
              </li>
              <li class="no-suggestions-found" v-if="!loadingSuggestions && !locations.length">
                <span v-translation="{ type: 'label', code: 'label_no_location_suggestions' }"></span>
              </li>              
              <li
                @click="selectLocation(location)"                 
                v-for="(location, index) in locations" :key="'location-' + index" 
                :class="{'active': location === currentLocation, 'hide-option': location.default && !currentLocation.length }"
                >
                <template v-if="!location.default">
                  <div class="locations">                  
                    <span class="value">{{ location.locations }}</span>
                    <span class="warehouse">{{ location.warehouse }}</span>
                  </div>
                  <div class="location-stock">
                    <span class="stock-total">{{ location.stock }}</span>
                  </div>
                </template>
                <template v-else-if="currentLocation.length">
                  <span class="value">{{ location.locations }}</span>
                  <i class="fa fa-times clear-location-icon"></i>
                </template>
              </li>
            </ul>
          </div>
        </div>
        <transition name="fade">
         
        </transition>
      </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    orderLine: { type: Object, required: true }
  },
  data () {
    return {
      editing: false,
      currentLocation: this.orderLine.customFields.Location ? this.orderLine.customFields.Location : "",
      locations: [],
      loadingSuggestions: false,
      placeholder: window.vue.translations.label.label_modal_location
    }
  },
  computed: {
    ...mapGetters(['bespokeEndpoints']),
    locationEndpoint () {      
      return `${this.bespokeEndpoints.GSApiEndpoint}/api/stock/${this.orderLine.productId}/locations`;
    }
  },
  methods: {
    ...mapActions('shoppingCart', ['updateOrderLineLocation']),
    getLocations () {
      this.loadingSuggestions = true;
      axios.get(this.locationEndpoint).then(res => {
        let clearLocation = window.vue.translations.label.label_clear_location;
        this.loadingSuggestions = false;
        var locations = res.data;
        locations.unshift({ locations: clearLocation, default: true })
        this.locations = res.data;
      });
    },
    edit () {
      this.editing = true;
      this.getLocations();
    },
    toggleEditing () {
      setTimeout(() => {
        this.editing = false;
        this.locations = [];
      }, 500);
    },
    selectLocation (locationObj) {
      this.currentLocation = locationObj.default ? "" : locationObj.locations;
      this.saveLocationEv();
    },
    saveLocationEv () {
      this.$emit('saveLocation', { orderLine: this.orderLine, location: this.currentLocation });
      this.editing = false;
      this.$refs.orderLineLocation.blur();
    }
  }
}
</script>

<style>

</style>
