<template>
  <div class="cmp-elastic-product-details utlz-reset utlz-row">
    <!-- BESPOKE GS: Changed col-md-4 to col-md-5 -->
    <div class="utlz-col-md-5 column-left">
      <utlz-gallery :product="productDetails"></utlz-gallery>

      <!-- BESPOKE GS: Added product image banners, this should be in the standard in a future release -->      
      <div class="product-banners" v-if="productDetails">
        <div v-for="(banner, index) in productImageBanners" :key="'prod-banner-wrapper-' + index" :class="{ [banner.position]: true }" class="prod-banner-wrapper">
          <div v-for="(bannerVal, ind) in banner.values" :key="'prod-banner-wrapper-' + ind">
            <span class="text-banner" v-if="bannerVal.Type === 0" :style="'background-color:' + bannerVal.Color + ';color: ' + bannerVal.TextColor">
              {{ bannerVal.Text }}
            </span>
            <img class="img-banner" v-if="bannerVal.Type === 1" :src="'/' + bannerVal.Image" />
          </div>
        </div>
      </div>
      <!-- END BESPOKE GS -->
      
    </div>
    <!-- BESPOKE GS: Changed col-md-8 to col-md-7 -->
    <div class="utlz-col-md-7 column-right">
      <div class="product-information">
        <span v-if="isSalesAction" v-translation="{ type: 'label', code: 'label_sales_action' }" class="action-banner"></span>
        <h1>{{productDetails.shortDescription}}</h1>
        <div class="product-code">
          <!-- BESPOKE GS: Added product code label -->
          <span v-translation="{ type: 'label', code: 'label_product_code' }" class="prod-code-label"></span>:
          <!-- END BESPOKE GS -->
          <span>{{productDetails.id}}</span>
        </div>

        <!-- BESPOKE GS: Added stock status buttons, only to be shwon to representatives -->
        <div v-if="bespokeUserInformation.isRepresentative" class="stock-status">
          <a @click="openStockModal('warehouseStock')" class="theme-primary button stock-btn warehouse-stock">
            <i class="fa fa-cube" aria-hidden="true"></i>
            <span v-translation="{ type: 'button', code: 'button_warehouse_stock_modal_text' }"></span>
          </a>
          <a @click="openStockModal('warehouseLocationStock')" class="theme-primary button stock-btn warehouse-stock-location">
            <i class="fa fa-hospital-o" aria-hidden="true"></i>
            <span v-translation="{ type: 'button', code: 'button_warehouse_location_stock_modal' }"></span>
          </a>
          <a @click="openStockModal('productEtaStock')" class="theme-primary button stock-btn stock-eta">
            <i class="fa fa-clock-o" aria-hidden="true"></i>
            <span v-translation="{ type: 'button', code: 'button_product_eta_modal_text' }"></span>
          </a>
        </div>
        <!-- END BESPOKE GS -->

        <div class="ph-product-price" v-if="productDetails.prices.length">
          <utlz-product-price :prices="productDetails.prices[0]"></utlz-product-price>
        </div>

        <utlz-tabs :tabs="tabs">

          <!-- <template v-slot:description>
            <div class="prod-desc-container" :class="{'expandable': useCollapse, 'expanded': expanded  }">
              <div ref="productDescription" class="prod-desc-wrapper">
                <div class="product-long-description" v-html="productDetails.longDescription">
                </div>
              </div>
            </div>
            <a v-if="useCollapse" @click="expanded = !expanded" class="collapse-button theme-primary">
              <div v-if="!expanded" class="read-more">
                <span v-translation="{ type: 'button', code: 'link_description_read_more' }"></span>
                <i class="fa fa-angle-down" aria-hidden="true"></i>
              </div>
              <div v-if="expanded" class="read-less">
                <span v-translation="{ type: 'button', code: 'link_description_read_less' }"></span>
                <i class="fa fa-angle-up" aria-hidden="true"></i>
              </div>
            </a>
          </template> -->

          <template v-slot:videos>
            <div class="product-videos" v-if="videos.length">
              <utlz-product-videos :videos="videos"></utlz-product-videos>
            </div>
          </template>

          <template v-slot:documents v-if="documents.length">
            <div class="product-documents">
              <utlz-product-documents :documents="documents"></utlz-product-documents>
            </div>
          </template>
        </utlz-tabs>

        <div class="product-specifications">
          <!-- BESPOKE GS: Disable specifications header -->
          <!-- <h2 v-translation="{ type: 'title', code: 'title_specifications' }"></h2> -->
          <!-- END BESPOKE GS -->
          <div class="spec-list">
            <div class="spec-item" v-for="prop in productProperties" :key="prop.key">
              <div class="spec-desc">
                {{prop.key}}
              </div>
              <div class="spec-value">
                {{prop.value}}
              </div>
            </div>
          </div>
        </div>
        
        <!-- BESPOKE GS-754 -->
        <div class="long-desc" v-html="productDetails.longDescription">
        </div>
        <!-- END BESPOKE GS-754 -->

        <utlz-group-dropdown :variants="productVariants" />

        <utlz-product-stock :product="productDetails"
                            :stock="productDetails.stock"
                            :stockTotal="productDetails.stock"></utlz-product-stock>

        <div class="order-product">
          <!-- BESPOKE GS: Added useTextOrderButton -->
          <utlz-order-product v-if="showOrderProductControl"
                              :product="productDetails"
                              :useOrderComment="useOrderLineComments"
                              :useTextOrderButton="true">
          </utlz-order-product>
          <!-- END BESPOKE GS -->

          <transition name="fade">
            <p v-if="!showOrderProductControl && showStock" class="out-of-stock-msg">
              <i class="fa fa-warning"></i>
              <span v-translation="{ type: 'label', code: 'label_out_of_stock' }"></span>
            </p>
          </transition>
        </div>

        <!-- BESPOKE -->
        <div class="unique-label">
          <span v-translation="{ type: 'text', code: 'txt_unique_product' }"></span>
        </div>
        <!-- END BESPOKE -->

        <!-- BESPOKE GS: Disable favorites, since it's loaded in OrderProduct -->
        <!-- <div class="favorite">
        <utlz-favorites-control v-if="showFavorites" :product="productDetails" :useDescription="true"></utlz-favorites-control>
      </div> -->
        <!-- END BESPOKE GS -->
      </div>
    </div>
    <div class="utlz-col-12 margin-bottom-30"></div>
    <!-- BESPOKE GS: Disable matrix -->
    <!-- <div v-if="showOrderProductControl && productDetails.prices.length && productVariants.length" class="utlz-col-md-12">
    <utlz-group-matrix :variants="productVariants" />
  </div> -->
    <!-- END BESPOKE GS -->
    <div v-if="relatedProducts.upSelling.length" class="related-products upselling utlz-col-12 ">
      <utlz-product-carousel :products="relatedProducts.upSelling"
                             :blockSize="relatedProductBlockSize"
                             :title="titleUpSelling"></utlz-product-carousel>
    </div>

    <div v-if="relatedProducts.crossSelling.length" class="related-products upselling utlz-col-12 ">
      <utlz-product-carousel :products="relatedProducts.crossSelling"
                             :blockSize="relatedProductBlockSize"
                             :title="titleCrossSelling"></utlz-product-carousel>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import ProductPrice from 'src/components/webshop/product-price/ProductPrice.vue';
import OrderProduct from 'src/components/webshop/order-product/OrderProduct.vue';
import ProductStock from 'src/components/webshop/product-stock/ProductStock.vue';
import ProductGroupMatrix from 'src/components/webshop/products/product-group/ProductGroupMatrix.vue';
import ProductGroupDropdown from 'src/components/webshop/products/product-group/ProductGroupDropdowns.vue';
import FavoritesControl from 'src/components/webshop/favorites/FavoritesControl.vue';
  import Gallery from 'src/components/ui/gallery/Gallery.vue';
  import Tabs from 'src/components/ui/tabs/Tabs.vue';
  import ProductVideos from 'src/components/webshop/products/product-videos/ProductVideos.vue';
  import ProductDocuments from 'src/components/webshop/products/product-documents/ProductDocuments.vue';
  import ProductCarousel from 'src/components/webshop/products/product-carousel/ProductCarousel.vue';
export default {
  components: {
    'utlz-product-price': ProductPrice,
    'utlz-order-product': OrderProduct,
    'utlz-product-stock': ProductStock,
    'utlz-group-matrix': ProductGroupMatrix,
    'utlz-group-dropdown': ProductGroupDropdown,
    'utlz-favorites-control': FavoritesControl,
    'utlz-gallery': Gallery,
    'utlz-tabs': Tabs,
    'utlz-product-carousel': ProductCarousel,
    'utlz-product-videos': ProductVideos,
    'utlz-product-documents': ProductDocuments
  },
  data () {
      return {
        expanded: false,
        productDescriptionHeight: 0,
        titleUpSelling: window.vue.translations.title.title_product_relations,
        titleCrossSelling: window.vue.translations.title.title_product_relations_cross,
        tabs: [
          { id: 'description', description: window.vue.translations.label.label_tab_product_desc, active: true }
        ]
      }
    },
    watch: {
      documents(val) {
        if (val.length) {
          this.tabs.push({ id: 'documents', description: window.vue.translations.label.label_tab_product_documents, active: false });
        } else {
          this.tabs.splice(this.tabs.findIndex(tab => tab.id === 'documents'), 1);
        }
      },
      videos(val) {
        if (val.length) {
          this.tabs.push({ id: 'videos', description: window.vue.translations.label.label_tab_product_videos, active: false });
        } else {
          this.tabs.splice(this.tabs.findIndex(tab => tab.id === 'videos'), 1);
        }
      }
  },
  computed: {
    ...mapState('productDetails', ['productDetails', 'productVariants', 'relatedProducts', 'videos']),
    // BESPOKE GS
    // Added bespokeUserInformation
    ...mapGetters(['showStock', 'showPrices', 'showFavorites', 'showOrderProduct', 'stockLimit', 'useOrderLineComments', 'bespokeUserInformation', 'bespokeGlobalWs', 'relatedProductBlockSize']),
    // END BESPOKE GS
    ...mapGetters('productDetails', ['documents']),
    useCollapse () {
      return this.productDescriptionHeight > 150;
    },
    isSalesAction () {
      return this.productDetails.prices.length > 0 ? this.productDetails.prices[0].isSalesAction : false;
    },
    showOrderProductControl () {
      if (!this.showPrices) {
        //BESPOKE GS
        if (this.bespokeGlobalWs.useCounter) {
          return true;
        } else {          
          return false;
        }
        //END BESPOKE
      } else if (this.showOrderProduct) {
        if (!this.stockLimit) {
          return true;
        } else if (this.stockLimit && this.productDetails.stock !== null) {
          return this.stockLimit && this.productDetails.stock.stockTotal > 0;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    productProperties () {
      // BESPOKE GS-772
      // Delete product type and product group from the properties
      let props = { ...this.productDetails.properties };
      delete props[window.vue.translations.label.label_prod_desc_group];
      delete props[window.vue.translations.label.label_prod_desc_type];

      return Object.keys(props).map(x => {
        return { key: x, value: props[x] }
      });
      // END BESPOKE GS-772
    },
    images () {
      let images = [];

      images.push(this.productDetails.imageUrl)

      this.productDetails.images.forEach(element => {
        images.push(element);
      });

      return images
    },
    productImageBanners() {
      if (this.productDetails.productImageBanners) {        
        return this.productDetails.productImageBanners.filter(banner => banner.show === true);
      }
    }
  },
  updated() {
    if (this.$refs.productDescription !== undefined) {
      this.productDescriptionHeight = this.$refs.productDescription.clientHeight;
    }
  },
  created() {
    this.initDetails();
  },
  methods: {
    ...mapActions('productDetails', ['initDetails']),
    // BESPOKE GS: Added methods to open stock modals
    openStockModal (type) {
      window.getProductStockStatus(this.productDetails.id, type);
    }
    // END BESPOKE GS
  }
}
</script>

<style>

</style>
