import { formatPrice } from 'src/utilities/helpers';

export class OrderLine {
  constructor (orderLine) {
    this.product = null;
    this.shoppingCartId = orderLine.ShoppingCartId;
    this.productId = orderLine.ProductId;
    this.quantity = parseInt(orderLine.Quantity);
    this.computedQuantity = null;
    this.totalOrderLinesQuantity = orderLine.TotalCount;
    this.rawQuantity = parseInt(orderLine.Quantity);
    this.unitCode = orderLine.UnitCode;
    this.isFree = orderLine.IsFree;
    this.lineId = orderLine.LineId;
    this.comments = orderLine.Comments;
    this.exceedsStock = false;
    this.customFields = orderLine.CustomFields;
    this.totals = {
      price: '',
      rawPrice: 0
    };
  }

  setProduct (product) {
    this.product = product;
  }

  getComputedQuantity () {
    let quantity = this.quantity;
    if (this.product.units) {
      quantity = this.product.units.filter(unit => unit.code === this.unitCode)[0].quantity * this.quantity;
    } else if (this.product.saleUnit > 1) {
      quantity = this.product.saleUnit * this.quantity;
    }
    return quantity;
  }

  setComputedQuantity () {
    this.computedQuantity = this.getComputedQuantity();
  }


  // BESPOKE GS
  // Added check for volume discount in the totals 
  // as fixed in te phoenix beta release
  setOrderLineTotal () {
    let price, totals;
    const quantity = this.getComputedQuantity();

    let volumeIndex = 0;

    this.product.prices.forEach((price, index) => {
      if (quantity >= price.quantity) {
        volumeIndex = index;
      }
    });

    price = this.product.prices[volumeIndex].rawPrice;
    totals = price * quantity;

    this.totals = {
      price: formatPrice(price),
      rawPrice: price,
      totalPrice: formatPrice(totals),
      totalRawPrice: totals,
      isSalesAction: this.product.prices[volumeIndex].isSalesAction
    };
  }
  // END BESPOKE GS
}
