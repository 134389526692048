<template>
  <div class="cmp-quantity-btns">
    <div class="quantity-btns-wrapper flex-row-nowrap align-center">
      <a class="quantity-btn button square small decrement fa fa-minus" @click="decrement"></a>
      <!-- BESPOKE GS-528: Added tabindex="-1" so that a user cannot tab into it coming from the search field -->
      <!-- BESPOKE GS-553: Added @keypress.enter="manualQuantity" ref="quantityfield" -->
      <input class="quantity-fld" type="number" min="0" v-model="quantity" @change="manualQuantity" @keypress.enter="manualQuantity" ref="quantityfield">
      <!-- END BESPOKE GS-528 and GS-553 -->
      <a class="quantity-btn button square small increment fa fa-plus" @click="increment"></a>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    // BESPOKE GS
    orderLine: { type: Object, required: false, default: null},
    // END BESPOKE GS
    initialQuantity: { type: Number, required: false, default: 1 },
    quantityFactor: { type: Number, required: false, default: 1 },
    allowZeroQuantity: { type: Boolean, required: false, default: false }
  },
  data () {
    return {
      quantity: this.initialQuantity
    }
  },
  watch: {
    initialQuantity() {
      this.quantity = this.initialQuantity;
    }
  },
  methods: {
    quantityChanged () {
    // BESPOKE GS
    // Check increasible if orderLine is being used as primary data
      if (this.orderLine !== undefined && this.orderLine !== null) {
        if (this.quantity > parseInt(this.orderLine.customFields.MaxOrderAmount) && parseInt(this.orderLine.customFields.MaxOrderAmount) !== -1 && this.orderLine.customFields.MaxOrderAmount !== undefined) {
          this.quantity = parseInt(this.orderLine.customFields.MaxOrderAmount);
        }
      }      
      // END BESPOKE GS
      this.$emit('quantityChanged', { quantity: parseInt(this.quantity) });
    },
    decrement () {
      if (this.quantity <= this.quantityFactor) {
        if (this.allowZeroQuantity) {
          this.quantity = 0;
          this.quantityChanged();
        } else {
          this.quantity = this.quantityFactor;
        }
      } else {
        this.quantity -= this.quantityFactor;
        this.quantityChanged();
      }
    },
    increment () {
      this.quantity += this.quantityFactor;
      this.quantityChanged();
    },
    manualQuantity () {
      if (Math.sign(this.quantity) <= 0 || this.quantity === '') {
        this.quantity = this.quantityFactor;
      } else if (this.quantity % this.quantityFactor !== 0) {
        this.quantity = Math.ceil(this.quantity / this.quantityFactor) * this.quantityFactor;
      }
      this.quantityChanged();
      // BESPOKE GS-553
      // Remove focus from input field after clicking enter
      this.$refs.quantityfield.blur();
      // END BESPOKE GS-553
    }
  }
}
</script>

<style>

</style>
