<template>
  <div class="cmp-scan-tool utlz-col-12">
      <span id="scroll-anchor-scantool"></span>
      <div class="scan-tool-wrapper" :class="{ 'sticky': setSticky, 'checking-gs-stock': checkingGsStock || loadingOrderLines }">
        <div class="input-wrapper">
          <input type="text" 
            ref="scantoolInput"
            :placeholder="barcodeFieldPlaceholder"
            @keyup="searchProductByString()"
            @keydown.enter="updateByEnter()"
            @focus="searchProductByString()"
            @blur="blurBarcodeField()"
            v-model="barcodeField"
            class="barcode-input"
          />
          <div class="results-wrapper">
            <ul class="scan-lines">
              
              <li v-if="noResultsFound && !loadingProducts && barcodeField.length > 3 && !fieldBlurred" class="scan-line no-scanned-products search-bar-msg">
                <span v-translation="{ type: 'label', code: 'label_no_scanned_products_found' }"></span>
              </li>
              
              <li v-if="loadingProducts" class="scan-line searching-scanned-products search-bar-msg">
                <span v-translation="{ type: 'label', code: 'label_loading_scanned_products' }"></span>
                <span class="loader"></span>
              </li>

              <li @click="addProductToCart(product.id)" v-for="product in scannedProducts" :key="product.id" class="scan-line">
                <div class="prod-image">
                  <img :src="product.imageUrl" />       
                </div>
                <div class="prod-header">        
                  <span class="prod-code">{{ product.id }}</span>
                  <span class="prod-desc">{{ product.shortDescription }}</span>
                </div>   
                <div class="size">
                  <span v-if="product.properties['Maten (in cm)'] !== undefined">
                    {{ product.properties['Maten (in cm)'] }}
                  </span>
                </div>
              </li>
            </ul>
          </div>        
        </div>
      </div>  
  </div>
</template>

<script>
import axios from 'axios';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import _ from 'lodash';
export default {
  data () {
    return {
      setSticky: false,
      loadingProducts: false,
      noResultsFound: false,
      fieldBlurred: true,
      barcodeField: '',
      barcodeFieldPlaceholder: window.vue.translations.label.label_barcode_scanner_ph,
      lastTypedString: '',
      addToCartInProgress: false
    }
  },
  watch: {
    checkingGsStock: (newVal) => {
      $('.barcode-input').attr('disabled', newVal); 
      if (!newVal) {
        $('.barcode-input').focus(); 
      }
    },
  },
  computed: {
    ...mapGetters('shoppingCart', ['checkingGsStock', 'loadingOrderLines', 'scannedProducts'])
  },
  methods: {
    ...mapMutations('shoppingCart', ['clearScannedProducts']),
    ...mapActions('shoppingCart', ['addToCart', 'getScannedProducts']),
    addProductToCart(prodCode) {
      this.addToCart(this.getPayload(prodCode.trim().toUpperCase())).then(addedToCart => {
        this.barcodeField = '';      
        this.addToCartInProgress = false;  
      });;
    },
    blurBarcodeField () {
      setTimeout(() => {        
        this.clearScannedProducts();
        this.fieldBlurred = true;
      }, 500);
    },
    updateByEnter () {
      const payload = {
        prod_code: this.barcodeField,
        ord_qty: 1,
        unit_code: "",
        prod_comment: ''
      };
      if (this.addToCartInProgress === false) {        
        this.addToCartInProgress = true; 

        this.addToCart(payload).then(addedToCart => {
          this.barcodeField = '';
          this.addToCartInProgress = false;
        });
      }
    },
    searchProductByString () {
      this.fieldBlurred = false;
      if (this.barcodeField.length > 3) {        
        // If only one product is found, we add the product directly to the cart
        if (this.addToCartInProgress === false) {
          this.loadingProducts = true;
          this.addToCartInProgress = true;

          this.getScannedProducts(this.barcodeField).then(res => {          
            if (res === 'foundExactProduct') {
              this.addProductToCart(this.barcodeField.trim().toUpperCase());
              this.noResultsFound = false;
            } else if (res === 'foundProducts') {
              this.noResultsFound = false;
              this.addToCartInProgress = false;
            } else if (res === 'noProductsFound') {
              this.noResultsFound = true;
              this.addToCartInProgress = false;
            }
            this.loadingProducts = false;
          });
        }        
      } else {
        this.clearScannedProducts();
        this.loadingProducts = false;
        this.noResultsFound = false;
      }
    },
    getPayload (prodCode) {
      return {
        prod_code: prodCode ? prodCode : this.barcodeField,
        ord_qty: 1,
        unit_code: "",
        prod_comment: ''
      };
    }
  },
  created () {
    // Setup debounce event on keyup events
    this.searchProductByString = _.debounce(this.searchProductByString, 500);

    document.addEventListener('scroll', ()=> {
      this.setSticky = document.querySelector("#scroll-anchor-scantool").getBoundingClientRect().top < 0;
    });
  }
}
</script>

<style>

</style>
