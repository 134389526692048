<template>
  <!-- BESPOKE GS: Disable product filters if there are no products found, since this may be caused by unique products filtering -->
  <div v-if="!noProductsFound" class="cmp-elastic-product-filters utlz-reset col-md-12">
    <!-- END BESPOKE GS -->

    <transition name="slide-fade">
      
      <!-- BESPOKE GS: Removed v-if="showMobileFilters" -->
      <div class="filters-container" :ref="'filters-container'">
      <!-- END BESPOKE GS -->

        <!-- BESPOKE GS: Disabled toggle mobile filters button -->
        <!-- <a v-if="showMobileFilters" @click="toggleMobileFilters()" class="close-filters-btn show-mobile">
          <i class="fa fa-times"></i>
        </a> -->
        <!-- END BESPOKE GS -->

        <div v-for="(filterGroup, i) in filters" :key="'filter-group-' + i" class="filters-wrapper" :ref="'filter-group-wrapper' + i" :class="{ 'collapse-group': screenWidth < 991 }">
          <div class="filter-header" @click="collapseGroup('filter-group-wrapper' + i)">
            <h2>{{ filterGroup.label }}</h2>

            <!-- BESPOKE GS: Added total active filters counter -->
            <span v-if="filterIsActive(i) > 0 && screenWidth < 992" class="active-filter-counter">{{ filterIsActive(i) }}</span>
            <!-- END BESPOKE GS -->

            <!-- BESPOKE GS: Added gs-icon -->
            <i class="gs-icon gs-arrow-up"></i>
            <!-- END BESPOKE GS -->
          </div>
          <div class="filter-content">
            <ul :ref="'filter-group-list' + i" :class="{ collapsed: filterGroup.value.length > 5 }">
              <li v-for="(filterObj, id) in filterGroup.value" :key="'filter-group-item-' + id">
                <div class="checkbox-group">
                  <input type="checkbox" :id="'checkbox-filter-' + filterObj.key + '-' + filterGroup.id" @change="checkedFilter($event, { id: filterGroup.id, value: filterObj.key, elemId: 'checkbox-filter-' + filterObj.key  + '-' + filterGroup.id })" :checked="filterObj.active === true">
                  <label :for="'checkbox-filter-' + filterObj.key + '-' + filterGroup.id">
                    <span class="key">
                      {{ filterObj.key }}
                    </span>
                    <span class="count">
                      ({{ filterObj.value }})
                    </span>
                  </label>
                </div>
              </li>
            </ul>
            <div v-if="filterGroup.value.length > 5" class="collapse-btns" @click="collapseList('filter-group-list' + i)">
              <div class="show-more-btn">
                <span class="text-link">
                  <span v-translation="{ type: 'label', code: 'label_filter_collapse_more' }"></span>
                </span>

                <!-- BESPOKE GS: Disabled total collapsed count -->
                <!-- <span class="total-collapsed count">({{ filterGroup.value.length - 5 }})</span> -->
                <!-- END BESPOKE GS -->

                <!-- BESPOKE GS: Changed .fa.fa-angle-double-down to .gs-icons.gs-arrow-down-->
                <i class="gs-icons gs-arrow-down"></i>
                <!-- END BESPOKE GS -->
              </div>
              <div class="show-less-btn">
                <span class="text-link">
                  <span v-translation="{ type: 'label', code: 'label_filter_collapse_less'}"></span>
                  <!-- BESPOKE GS: Changed .fa.fa-angle-double-up to .gs-icons.gs-arrow-down -->
                  <i class="gs-icons gs-arrow-down"></i>
                  <!-- END BESPOKE GS -->
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

    </transition>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
export default {
  computed: {
    ...mapState('elastic', ['filters', 'activeFilters']),    
    // BESPOKE GS: Added screenWidth, noProductsFound mapgetters
    ...mapGetters('elastic', ['showMobileFilters', 'noProductsFound']),
    // END BESPOKE GS
    ...mapGetters(['screenWidth'])
  },
  methods: {
    ...mapActions('elastic', ['updateFilters', 'toggleMobileFilters']),
    checkedFilter (ev, filter) {
      const payload = {
        filterActive: ev.target.checked,
        filterId: filter.id,
        filterValue: filter.value
      };
      if (ev.target.checked) {
        document.getElementById(filter.elemId).checked = false;
      } else {
        document.getElementById(filter.elemId).checked = true;
      }
      this.updateFilters(payload);
    },
    collapseList ($event) {
      this.$refs[$event][0].classList.toggle('collapsed');
    },
    collapseGroup ($event) {
      this.$refs[$event][0].classList.toggle('collapse-group');
    },
    // BESPOKE GS
    // Added counter for total active filters
    filterIsActive (index) {
      const id = this.filters[index].id;
      if (this.activeFilters[id] !== undefined) {
        return this.activeFilters[id].length;
      } else {
        return 0;
      }
    }
    // END BESPOKE GS
  }
}
</script>
<style>

</style>
